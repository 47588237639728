import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import rightArrow from "../../assets/img/Right.png";
import bottomIcon1 from "../../assets/img/bottomIcon-1.png";
import bottomIcon2 from "../../assets/img/bottomIcon-2.png";
import bottomIcon3 from "../../assets/img/bottomIcon-3.png";
import bottomIcon4 from "../../assets/img/bottomIcon-4.png";
import GenderIcon from "../../assets/img/gender-icon.png";
import saveIcon from "../../assets/img/SaveIcon.png";
import world from "../../assets/img/worldIcon.svg";
import pray from "../../assets/img/prayIcon.svg";
import { Modal, ModalBody } from "reactstrap";
import Header from "../../core/headers/Header";
import Footer from "../../core/footers/footer";
import Login from "../login/login";
import { useDispatch, useSelector } from "react-redux";
import AppLoader from "../../core/Loaders/appLoader/appLoader";
import savedJobIcon from "../../assets/img/saveJob.svg";
import { ApplyJob, SavedJob } from "../../store/job/jobAction";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../core/Loaders/ComponentLoader";
import { profileApi } from "../../api/profileApi";
import FeatureJobCard from "../SidebarContent/FeatureJob/FeatureJob";
const WorkerJobSearchList = (props) => {
  let {
    jobList,
    setAge,
    setGender,
    setExperience,
    setNationality,
    searchJobs,
    user,
    searchAllJobs,
    setSearch,
    setLocation,
    jobType,
    setJobType,
    nationalityList,
    setJobFilter,
    searchSavedJobs,
    searchAppliedJobs,
    type,
    isLoggedIn,
    skip,
    locationList,
    getLocation,
    location,
    search,
    jobAge,
    jobGender,
    jobExp,
    selectFilter,
    setSelectFilter,
    currencyCodes,
  } = props;
  console.log(user);
  const { isPageLoading } = useSelector((state) => state.loader);
  const [AccessPermission, setAccessPermission] = useState(false);
  const toggle = () => setAccessPermission(!AccessPermission);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const applyJob = (id) => {
    if (!isLoggedIn) navigate("/login");
    dispatch(ApplyJob(id))
      .then((res) => {
        if (res.success) {
          // alert(res?.message);
          toast.success(res?.message, {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        } else {
          // alert(res?.response?.data?.message)
          toast.error(res?.response?.data?.message, {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        }
      })
      .catch((err) =>
        toast.error(err?.response?.data?.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        })
      );
  };
  const saveJob = (id) => {
    if (!isLoggedIn) navigate("/login");
    dispatch(SavedJob(id))
      .then((res) => {
        if (res.success) {
          toast.success(res?.message);
        } else {
          toast.error(res?.response?.data?.message);
        }
      })
      .catch((err) => toast.error(err?.response?.data?.message));
  };

  console.log(user?.worker?.type, "userussersrsrerer");
  const createSearch = (type_id, search) => {
    profileApi.createSearchHistory({
      platform_type: "wp",
      type: "job",
      type_id,
      search,
    });
  };

  const time = (date) => {
    const now = new Date();
    const createdAt = new Date(date);

    const timeDifference = now.getTime() - createdAt.getTime();
    const secondsDifference = Math.floor(timeDifference / 1000);
    const minutesDifference = Math.floor(secondsDifference / 60);
    const hoursDifference = Math.floor(minutesDifference / 60);
    const daysDifference = Math.floor(hoursDifference / 24);

    let timeAgo;

    if (daysDifference > 0) {
      timeAgo =
        daysDifference === 1 ? "1 day ago" : daysDifference + " days ago";
    } else if (hoursDifference > 0) {
      timeAgo =
        hoursDifference === 1 ? "1 hour ago" : hoursDifference + " hours ago";
    } else if (minutesDifference > 0) {
      timeAgo =
        minutesDifference === 1
          ? "1 minute ago"
          : minutesDifference + " minutes ago";
    } else {
      timeAgo = "Just Now";
    }
    return timeAgo;
  };
  return (
    <>
      <Header
        isworkerpoolJobList={true}
        setAge={setAge}
        setNationality={setNationality}
        setGender={setGender}
        setExperience={setExperience}
        user={user}
        setSearch={setSearch}
        setLocation={setLocation}
        jobType={jobType}
        searchJobs={searchJobs}
        type={type}
        nationalityList={nationalityList}
        setJobType={setJobType}
        locationList={locationList}
        getLocation={getLocation}
        location={location}
        search={search}
        jobAge={jobAge}
        jobGender={jobGender}
        jobExp={jobExp}
      />
      {isPageLoading && <AppLoader />}
      <section className="three-column-worker-section jobFilterList">
        <div className="container-big">
          <div className="row">
            <div className="col-xl-9 col-lg-9 col-md-12">
              <div className="row">
                <div className="col-12 mb-3">
                  <div className="jobListHeader d-flex justify-content-between align-items-center">
                    <h3 className="NumberOfjobList">
                      {jobList?.totalCount} Jobs based on Your Desired
                      Preferences
                    </h3>
                    <ul class="filterBtn d-flex flex-wrap gap-2">
                      <li>
                        <button
                          class={
                            selectFilter == "saved" ? "btns active" : "btns"
                          }
                          onClick={(e) => {
                            !isLoggedIn
                              ? navigate("/login")
                              : searchSavedJobs();
                            setSelectFilter("saved");
                          }}
                        >
                          {" "}
                          Saved Jobs
                        </button>
                      </li>
                      <li>
                        <button
                          class={
                            selectFilter == "applied" ? "btns active" : "btns"
                          }
                          onClick={(e) => {
                            !isLoggedIn
                              ? navigate("/login")
                              : searchAppliedJobs();
                            setSelectFilter("applied");
                          }}
                        >
                          Apply Jobs
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-12 mb-3">
                  <div className="two-column-worker-section bg-transparent p-0">
                    <div className="row">
                      {jobList?.jobList?.map((ele) => {
                        return (
                          <>
                            <div className="col-12">
                              <div className="col-outer flex-column">
                                <div className="d-flex justify-content-between">
                                  <div className="left">
                                    <h3>{ele?.title}</h3>
                                    <span className="detail">
                                      {ele?.company_name}
                                    </span>
                                  </div>
                                  <div className="right d-flex gap-lg-4 gap-3 align-items-center">
                                    <button
                                      className={
                                        ele?.user_id !== user?.id ||
                                        user?.worker?.type !== "provider"
                                          ? "SaveBtn border-0 bg-transparent"
                                          : "d-none"
                                      }
                                      onClick={() => saveJob(ele?.id)}
                                    >
                                      {ele?.savedJob &&
                                      ele?.savedJob?.status ? (
                                        <>
                                          <img
                                            src={savedJobIcon}
                                            alt="Save-Job"
                                            width={25}
                                          />
                                        </>
                                      ) : (
                                        <>
                                          <img
                                            src={saveIcon}
                                            alt="Save-Job"
                                            width={25}
                                          />
                                        </>
                                      )}
                                    </button>

                                    {/* <button onClick={()=> applyJob(ele?.id)} className="apply-btn border-0" disabled={ele?.appliedJob && ele?.appliedJob?.apply_status != "rejected"}>
                                {ele?.appliedJob && ele?.appliedJob?.apply_status != "rejected" ? "Applied" : "Apply Job"}
                              </button> */}
                                    <div className="d-flex flex-column">
                                      <button
                                        className="apply-btn border-0"
                                        onClick={() => {
                                          createSearch(ele?.id, ele?.title);
                                          navigate(`/job/${ele?.id}`);
                                        }}
                                      >
                                        View Job
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <div className="contentBx mt-2 overflow-hidden">
                                  {ele?.description?.length > 200 ? (
                                    <>
                                      <p
                                        dangerouslySetInnerHTML={{
                                          __html: ele?.description.slice(
                                            0,
                                            200
                                          ),
                                        }}
                                      ></p>
                                      <span>...</span>
                                    </>
                                  ) : (
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: ele?.description,
                                      }}
                                    ></p>
                                  )}
                                </div>
                                <div className="d-flex justify-content-between align-items-center">
                                  <div className="bottom-text">
                                    {ele?.job_location && (
                                      <span>
                                        <img src={bottomIcon1} alt="Icon" />{" "}
                                        {ele?.job_location}
                                      </span>
                                    )}
                                    {ele?.employment_type && (
                                      <span className="text-capitalize">
                                        <img src={bottomIcon2} alt="Icon" />{" "}
                                        {ele?.employment_type?.split("-")
                                          ?.length >= 2
                                          ? ele?.employment_type
                                              ?.split("-")
                                              ?.join(" ")
                                          : ele?.employment_type}
                                      </span>
                                    )}
                                    {ele?.gender ? (
                                      <span className="text-capitalize">
                                        <img
                                          src={GenderIcon}
                                          alt="GenderIcon"
                                        />{" "}
                                        {ele?.gender || "gender"}
                                      </span>
                                    ) : (
                                      <span className="text-capitalize">
                                        <img
                                          src={GenderIcon}
                                          alt="GenderIcon"
                                        />{" "}
                                        Male or Female
                                      </span>
                                    )}
                                    <span>
                                      <img src={bottomIcon3} alt="Icon" />{" "}
                                      {ele?.min_experience} {" - "}
                                      {ele?.max_experience}{" "}
                                      {ele?.max_experience > 1
                                        ? "years"
                                        : "year"}
                                    </span>
                                    <span>
                                      <img src={bottomIcon4} alt="Icon" />{" "}
                                      {Math.round(
                                        ele?.min_salary *
                                          currencyCodes[
                                            ele?.salary_type || "USD"
                                          ]
                                      ) >= 1000
                                        ? Math.round(
                                            (ele?.min_salary *
                                              currencyCodes[
                                                ele?.salary_type || "USD"
                                              ]) /
                                              1000
                                          ) + "K"
                                        : Math.round(
                                            ele?.min_salary *
                                              currencyCodes[
                                                ele?.salary_type || "USD"
                                              ]
                                          )}{" "}
                                      -{" "}
                                      {Math.round(
                                        ele?.max_salary *
                                          currencyCodes[
                                            ele?.salary_type || "USD"
                                          ]
                                      ) >= 1000
                                        ? Math.round(
                                            (ele?.max_salary *
                                              currencyCodes[
                                                ele?.salary_type || "USD"
                                              ]) /
                                              1000
                                          ) + "K"
                                        : Math.round(
                                            ele?.max_salary *
                                              currencyCodes[
                                                ele?.salary_type || "USD"
                                              ]
                                          )}{" "}
                                      {ele?.salary_type || "USD"}
                                    </span>
                                    {ele?.nationality?.length > 0 && (
                                      <span>
                                        <img src={world} alt="Icon" />{" "}
                                        {ele?.nationality}
                                      </span>
                                    )}
                                    {ele?.religion && (
                                      <span className="">
                                        <img src={pray} alt="Icon" />{" "}
                                        {ele?.religion}
                                      </span>
                                    )}
                                  </div>
                                  {time(ele?.createdAt)}
                                  {/* {Math.abs(
                                    new Date().getHours() -
                                      new Date(ele?.createdAt).getHours()
                                  ) === 0
                                    ? "Just Now"
                                    : Math.abs(
                                        new Date().getHours() -
                                          new Date(ele?.createdAt).getHours()
                                      ) === 1
                                    ? "1 hour ago"
                                    : Math.abs(
                                        new Date().getHours() -
                                          new Date(ele?.createdAt).getHours()
                                      ) + " hours ago"} */}
                                  {/* {new Date().getHours() -
                                    new Date(ele?.createdAt).getHours() ==
                                  0
                                    ? "Just Now"
                                    : new Date().getHours() -
                                      new Date(ele?.createdAt).getHours() +
                                      " hour ago"}{" "} */}
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}

                      {isPageLoading && skip >= 1 && <Loader />}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-12">
              <FeatureJobCard />
              <div className="gradientCard">
                <h3>
                  Benefits of <span>NobelPage</span>
                </h3>
                <p>
                  Enjoy even more add-on packages, offering additional benefits
                  at discounted pricing. Your Professional Networking Platform,
                  where Skills Win over Bias!
                </p>
                <div
                  className="actionGroup"
                  onClick={() =>
                    window.open(`${process.env.REACT_APP_WEB_STAGE_URL}/signup`)
                  }
                >
                  <p className="btn btn-white">
                    Join Now <img src={rightArrow} alt="right-arrow" />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};
export default WorkerJobSearchList;
