import React, { useEffect, useState } from "react";
import { Form, useLocation } from "react-router-dom";
import { Button, FormGroup, Input, Label, Progress } from "reactstrap";
import "../WorkerPoolDefaultForm.scss";
import FormInput from "../../../core/Inputs/FormInput";
import email from "../../../assets/img/a.svg";
import profile from "../../../assets/img/profile-circle.svg";
import pass from "../../../assets/img/shield-security.svg";
import DatePickerCustom from "../../../core/DatePicker/DatePickerReact";
import MobileInput from "../../../core/Inputs/MobileInput";
import Autocomplete from "react-autocomplete";
import backArrow from "../../../assets/img/backArrow.svg";
import whatsappIcon from "../../../assets/img/whatsapp.svg";
import HeaderLogo from "../../Header/header";
import AppLoader from "../../../core/Loaders/appLoader/appLoader";
import Select, { components } from "react-select";
import moment from "moment";
import AskNobePage from "../../login/component/AskNobePage";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";
import Loader from "../../../core/Loaders/ComponentLoader";
import PhoneInput from "react-phone-input-2";

const BasicInformation = (props) => {
  const {
    errorEmail,
    setErrorEmail,
    errorFirstName,
    setErrorFirstName,
    errorLastName,
    setErrorLastName,
    setErrorPassword,
    errorPhoneNumber,
    errorMessage,
    setPhoneCount,
    checkSignUpValidation,
    basicForm,
    setBasicForm,
    errorPassword,
    submitSignUpData,
    navigate,
    user,
    location,
    today,
    errorWhatsappNumber,
    countryList,
    experienceRegion,
    religionList,
    employmentTypeList,
    errorAbout,
    setErrorAbout,
    errorHeadline,
    setErrorHeadline,
    errorDob,
    setErrorDob,
    errorExp,
    setErrorExp,
    errorPrefCountry,
    setErrorPrefCountry,
    errorNationality,
    setErrorNationality,
    errorROE,
    setErrorROE,
    errorJobType,
    setErrorJobType,
    setCountinue,
    isProfilePage,
    setProfileField,
    isPageLoading,
    handleProfession,
    selectedCategory,
    setSelectedCategory,
    categoryArray,
    setCategoryArray,
    catergoryList,
    professionList,
    errorProfession,
    setErrorProfession,
    errorCategory,
    setErrorCategory,
    askNobelModal,
    setAskNobelModal,
    number,
    setNumber,
    sameAsPhone,
    setSameAsPhone,
    agency,
    setActivePage,
    setErrorGender,
    errorGender,
    setWhatsappCount,
    whatsappCount,
  } = props;

  useEffect(() => {}, [isPageLoading]);
  const { isLoading } = useSelector((state) => state.loader);

  const handleContinue = (e) => {
    e.preventDefault();
    if (checkSignUpValidation("all")) {
      submitSignUpData(e);
      if (errorMessage === "User Already Exist in Worker Pool") {
        toast.error("User Already Exist, please login");
        navigate("/login");
      }
    }
  };

  const handleSave = () => {
    if (checkSignUpValidation("all")) {
      submitSignUpData();
      window.scrollTo(0, 0);
      setProfileField("profile");
    }
  };

  console.log(
    new Date().getFullYear() - new Date(basicForm?.dob).getFullYear() - 17,
    "expDate"
  );

  return (
    <>
      {isPageLoading && <AppLoader />}
      {isLoading && <AppLoader />}
      {!isProfilePage && !agency && <HeaderLogo />}

      <div
        className={
          "basic-info-page workerpool-default-form-page" +
          (isProfilePage || agency ? " m-4" : "")
        }
      >
        <div className="container d-flex flex-column align-items-center">
          <div className="width-850 m-auto">
            {isProfilePage && (
              <div className="workerpool-default-form-page">
                <div
                  className="back-btn"
                  onClick={() => setProfileField("profile")}
                >
                  <img src={backArrow} alt="Icon" />
                </div>
              </div>
            )}
            <div className="head progress-box">
              <h3 className="mb-2">Basic Information</h3>
              {!isProfilePage && <Progress value={20} />}
            </div>
            <form className="form-box">
              {errorMessage && (
                <label className="label-input100 text-danger">
                  {errorMessage}
                </label>
              )}
              <div className="row">
                <FormGroup className="col-sm-6">
                  <FormInput
                    // containerClassName="col-lg-6"
                    label="First Name"
                    // icon={profile}
                    type="text"
                    onChange={(e) => {
                      setBasicForm({
                        ...basicForm,
                        first_name: e.target.value,
                      });
                      setErrorFirstName("");
                    }}
                    readOnly={location?.state?.fromNP ? true : false}
                    onBlur={() => checkSignUpValidation("firstName")}
                    placeholder="Enter first name"
                    error={location?.state?.fromNP ? "" : errorFirstName}
                    value={basicForm?.first_name}
                    max={50}
                  />
                </FormGroup>
                <FormGroup className="col-sm-6">
                  <FormInput
                    // containerClassName="col-lg-6"
                    label="Last Name"
                    // icon={profile}
                    type="text"
                    value={basicForm.last_name}
                    onChange={(e) => {
                      setBasicForm({
                        ...basicForm,
                        last_name: e.target.value,
                      });
                      setErrorLastName("");
                    }}
                    max={50}
                    readOnly={location?.state?.fromNP ? true : false}
                    onBlur={() => checkSignUpValidation("lastName")}
                    placeholder="Enter last name"
                    error={
                      location?.state?.fromNP && user?.last_name?.length > 0
                        ? ""
                        : errorLastName
                    }
                  />
                </FormGroup>
                <FormGroup className="col-sm-12">
                  <Label>About</Label>
                  <textarea
                    rows={5}
                    placeholder="Enter about"
                    maxLength="500"
                    value={basicForm.about}
                    onChange={(e) => {
                      setBasicForm({
                        ...basicForm,
                        about: e.target.value,
                      });
                      setErrorAbout("");
                    }}
                    readOnly={
                      location?.state?.fromNP && user?.about?.length > 0
                        ? true
                        : false
                    }
                    onBlur={() => checkSignUpValidation("about")}
                  />
                  {errorAbout && (
                    <label className="label-input100 text-danger">
                      {errorAbout}
                    </label>
                  )}
                </FormGroup>
                <FormGroup className="col-sm-12">
                  <Label>Headline</Label>
                  <Input
                    type="text"
                    placeholder="Enter headline"
                    value={basicForm.profile_headline}
                    onChange={(e) => {
                      setBasicForm({
                        ...basicForm,
                        profile_headline: e.target.value,
                      });
                      setErrorHeadline("");
                    }}
                    readOnly={
                      location?.state?.fromNP &&
                      user?.profile_headline?.length > 0
                        ? true
                        : false
                    }
                    onBlur={() => checkSignUpValidation("headline")}
                    maxLength={255}
                  />
                  {errorHeadline && (
                    <label className="label-input100 text-danger">
                      {errorHeadline}
                    </label>
                  )}
                </FormGroup>

                <div className="row">
                  <FormGroup className="col-3 col-sm-3">
                    <Label>Religion (optional)</Label>
                    <select
                      value={basicForm?.religion}
                      className="form-select"
                      onChange={(e) =>
                        setBasicForm({
                          ...basicForm,
                          religion: e.target.value,
                        })
                      }
                    >
                      <option value="">Select</option>
                      {religionList?.map((item, index) => (
                        <option value={item?.religion} key={index}>
                          {item?.religion}
                        </option>
                      ))}
                    </select>
                  </FormGroup>
                  <FormGroup className="col-sm-3 col-3">
                    <Label>Gender</Label>
                    <select
                      type="select"
                      className="form-select"
                      value={basicForm.gender}
                      onChange={(e) => {
                        setBasicForm({
                          ...basicForm,
                          gender: e.target.value,
                        });
                        setErrorGender("");
                      }}
                      onClick={() => checkSignUpValidation("gender")}
                    >
                      <option value="">Select</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>
                    {errorGender && (
                      <label className="label-input100 text-danger">
                        {errorGender}
                      </label>
                    )}
                  </FormGroup>
                  <FormGroup className="col-3 col-sm-3">
                    <Label>Date of Birth</Label>
                    <DatePickerCustom
                      value={basicForm.dob}
                      onChange={(date) => {
                        setBasicForm({
                          ...basicForm,
                          dob: date,
                        });
                        if (
                          basicForm?.experience &&
                          new Date().getFullYear() -
                            new Date(date).getFullYear() -
                            17 >
                            basicForm?.experience
                        ) {
                          setErrorExp("");
                        }
                      }}
                      placeholder="Select DOB"
                      max={moment().subtract(18, "years")._d}
                      leftAlign={true}
                      onBlur={() => checkSignUpValidation("dob")}
                    />
                    {errorDob && (
                      <label className="label-input100 text-danger">
                        {errorDob}
                      </label>
                    )}
                  </FormGroup>

                  <FormGroup className="col-sm-3 col-3">
                    <Label>Experience</Label>
                    <select
                      value={basicForm?.experience}
                      disabled={!basicForm?.dob}
                      className="form-select"
                      onChange={(e) => {
                        setBasicForm({
                          ...basicForm,
                          experience: e.target.value,
                        });
                        setErrorExp("");
                      }}
                      onBlur={() => checkSignUpValidation("exp")}
                    >
                      <option value="">Select</option>
                      <option value="0">Fresher</option>
                      <option value="1">1 Year</option>
                      <option value="2">2 Years</option>
                      <option value="3">3 Years</option>
                      <option value="4">4 Years</option>
                      <option value="5">5 Years</option>
                      <option value="6">6 Years</option>
                      <option value="7">7 Years</option>
                      <option value="8">8 Years</option>
                      <option value="9">9 Years</option>
                      <option value="10">10 Years</option>
                      <option value="11">11 Years</option>
                      <option value="12">12 Years</option>
                      <option value="12">13 Years</option>
                      <option value="14">14 Years</option>
                      <option value="15">15 Years</option>
                      <option value="16">16 Years</option>
                      <option value="17">17 Years</option>
                      <option value="18">18 Years</option>
                    </select>
                    {errorExp && (
                      <label className="label-input100 text-danger">
                        {errorExp}
                      </label>
                    )}
                  </FormGroup>
                </div>

                <FormGroup className="col-sm-6">
                  <FormInput
                    label="Email"
                    icon={email}
                    value={basicForm.email}
                    type="email"
                    onChange={(e) => {
                      setBasicForm({
                        ...basicForm,
                        email: e.target.value,
                      });
                      setErrorEmail("");
                    }}
                    readOnly={
                      location?.state?.fromNP || isProfilePage ? true : false
                    }
                    placeholder="Enter email"
                    onBlur={() => checkSignUpValidation("emailName")}
                    error={errorEmail}
                  />
                  {errorEmail ===
                    "Email already exists on Nobel Page, Please continue as Nobel Page" && (
                    <span
                      style={{ cursor: "pointer", color: "#19bcf4" }}
                      onClick={() =>
                        navigate("/login", {
                          state: { fromBI: true },
                        })
                      }
                    >
                      Click here
                    </span>
                  )}
                </FormGroup>
                {!isProfilePage && (
                  <FormGroup className="col-sm-6">
                    <FormInput
                      label="Password"
                      icon={pass}
                      value={basicForm.password}
                      type="password"
                      onChange={(e) => {
                        setBasicForm({
                          ...basicForm,
                          password: e.target.value,
                        });
                        setErrorPassword("");
                      }}
                      // readOnly={location?.state?.fromNP ? true : false}
                      placeholder="Enter password"
                      onBlur={() => checkSignUpValidation("password")}
                      error={errorPassword}
                    />
                  </FormGroup>
                )}

                <FormGroup className="col-sm-6">
                  <MobileInput
                    label="Phone"
                    onChange={(value, country) => {
                      setBasicForm({
                        ...basicForm,
                        phone: `+${country.dialCode}-${value.slice(
                          country.dialCode.length
                        )}`,
                      });
                      setPhoneCount(country.countryCode);
                    }}
                    readOnly={!!location?.state?.fromNP} // Cleaner way to check for boolean
                    value={basicForm.phone}
                    error={errorPhoneNumber}
                    onBlur={() => checkSignUpValidation("phoneNumber")}
                    placeholder="Enter phone"
                    containerClassName="form-input"
                  />
                </FormGroup>

                <FormGroup className="col-sm-6">
                  <div className="d-flex justify-content-between">
                    <label>
                      Whatsapp <img src={whatsappIcon} alt="Whatsapp Icon" />
                    </label>
                    <div className="mb-2">
                      <Input
                        type="checkbox"
                        name="sameAsPhone"
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          setSameAsPhone(isChecked);
                          setBasicForm({
                            ...basicForm,
                            whatsapp: isChecked ? basicForm.phone : "",
                          });
                        }}
                        checked={sameAsPhone} // changed `value` to `checked` for checkboxes
                        style={{ color: "#676A79", fontSize: "14px" }}
                      />
                      Same as Phone
                    </div>
                  </div>
                  <PhoneInput
                    country="us" // Default country
                    value={sameAsPhone ? basicForm.phone : basicForm.whatsapp}
                    disabled={sameAsPhone}
                    onChange={(value, country) => {
                      setBasicForm({
                        ...basicForm,
                        whatsapp: `+${country.dialCode}-${value.slice(
                          country.dialCode.length
                        )}`,
                      });
                      setWhatsappCount(country.countryCode);
                    }}
                    placeholder="Enter Whatsapp number"
                    onBlur={() => checkSignUpValidation("whatsappNumber")}
                  />

                  {/* Uncomment and use this if there's a need to show an error */}
                  {/* {error?.whatsapp && (
    <label className="label-input100 text-danger">
      {error.whatsapp}
    </label>
  )} */}
                </FormGroup>

                {[...Array(number)].map((_, i) => (
                  <div className="row m-0 p-0" key={i}>
                    <FormGroup className=" col-lg-6 col-md-6 col-12">
                      <Label>Category</Label>
                      <select
                        className="form-select"
                        value={selectedCategory}
                        onChange={(e) => {
                          setSelectedCategory(e.target.value);
                          setCategoryArray([...categoryArray, e.target.value]);
                          setErrorCategory("");
                          setBasicForm({ ...basicForm, profession: [] });
                          setErrorProfession("");
                        }}
                      >
                        <option value="">Select</option>
                        {catergoryList?.map((item, index) => (
                          <option value={item?.category} key={index}>
                            {item?.category}
                          </option>
                        ))}
                      </select>
                      {errorCategory && (
                        <label className="label-input100 text-danger">
                          {errorCategory}
                        </label>
                      )}
                    </FormGroup>
                    <FormGroup className="col-lg-6 col-md-6 col-12">
                      <Label>Profession</Label>

                      <Select
                        options={professionList?.map((ele) => ({
                          value: ele?.name,
                          label: ele?.name,
                        }))}
                        isMulti
                        onChange={(e) => {
                          handleProfession(e);
                        }}
                        onBlur={() => {
                          if (
                            !basicForm.profession ||
                            basicForm.profession.length <= 5
                          )
                            setErrorProfession("");
                        }}
                        defaultValue={basicForm.profession?.map((ele) => ({
                          value: ele,
                          label: ele,
                        }))}
                        value={basicForm.profession?.map((ele) => ({
                          value: ele,
                          label: ele,
                        }))}
                        isDisabled={!selectedCategory}
                      />

                      {errorProfession && (
                        <label className="label-input100 text-danger">
                          {errorProfession}
                        </label>
                      )}
                    </FormGroup>
                  </div>
                ))}
                {/* {number <5 &&  <span
                    style={{
                      cursor: "pointer",
                      color: "#19bcf4",
                      marginBottom: "1rem",
                    }}
                    onClick={() =>
                      setNumber(number+1)
                    }
                  >
                    Add More Professions
                  </span>} */}

                <FormGroup className="col-sm-6 preffer-country">
                  <Label>Preferred Country</Label>
                  <select
                    value={basicForm?.desire_country}
                    className="form-select"
                    onChange={(e) => {
                      setBasicForm({
                        ...basicForm,
                        desire_country: e.target.value,
                      });
                      setErrorPrefCountry("");
                    }}
                  >
                    <option value="">Select</option>
                    {countryList?.map((item, index) => (
                      <option value={item?.name} key={index}>
                        {item?.name}
                      </option>
                    ))}
                    {/* onBlur={() => checkSignUpValidation("prefCountry")} */}
                  </select>
                  {errorPrefCountry && (
                    <label className="label-input100 text-danger">
                      {errorPrefCountry}
                    </label>
                  )}
                </FormGroup>
                <FormGroup className="col-sm-6">
                  <Label>Nationality</Label>
                  <select
                    value={basicForm?.nationality}
                    className="form-select"
                    onChange={(e) => {
                      setBasicForm({
                        ...basicForm,
                        nationality: e.target.value,
                      });
                      setErrorNationality("");
                    }}
                  >
                    <option value="0">Select</option>
                    {countryList?.map((item, index) => (
                      <option value={item?.id} key={index}>
                        {item?.name}
                      </option>
                    ))}
                  </select>
                  {errorNationality && (
                    <label className="label-input100 text-danger">
                      {errorNationality}
                    </label>
                  )}
                </FormGroup>
                <FormGroup className="col-sm-6">
                  <Label>Region of Experience</Label>
                  <select
                    className="form-select"
                    value={basicForm?.experience_region}
                    onChange={(e) => {
                      setBasicForm({
                        ...basicForm,
                        experience_region: e.target.value,
                      });
                      setErrorROE("");
                    }}
                  >
                    <option value="0">Select</option>
                    {experienceRegion?.map((item, index) => (
                      <option value={item?.id} key={index}>
                        {item?.name} {","}
                        {item?.region}
                      </option>
                    ))}
                  </select>
                  {errorROE && (
                    <label className="label-input100 text-danger">
                      {errorROE}
                    </label>
                  )}
                </FormGroup>
                {/* <div className="col-sm-6">
                  <div className="row">
                    
                    <FormGroup className="col-sm-6">
                      <Label>Experience</Label>
                      <select
                        value={basicForm?.experience}
                        className="form-select"
                        onChange={(e) => {
                          setBasicForm({
                            ...basicForm,
                            experience: e.target.value,
                          });
                          setErrorExp("");
                        }}
                        onBlur={() => checkSignUpValidation("exp")}
                      >
                        <option>Select</option>
                        <option value="0">Fresher</option>
                        <option value="1">1 Year</option>
                        <option value="2">2 Years</option>
                        <option value="3">3 Years</option>
                        <option value="4">4 Years</option>
                        <option value="5">5 Years</option>
                        <option value="6">6 Years</option>
                        <option value="7">7 Years</option>
                        <option value="8">8 Years</option>
                        <option value="9">9 Years</option>
                        <option value="10">10 Years</option>
                        <option value="11">11 Years</option>
                        <option value="12">12 Years</option>
                        <option value="12">13 Years</option>
                        <option value="14">14 Years</option>
                        <option value="15">15 Years</option>
                        <option value="16">16 Years</option>
                        <option value="17">17 Years</option>
                        <option value="18">18 Years</option>
                      </select>
                      {errorExp && (
                        <label className="label-input100 text-danger">
                          {errorExp}
                        </label>
                      )}
                    </FormGroup>
                  </div>
                </div> */}
                {/* <FormGroup className="col-sm-6">
                  <Label>Religion (optional)</Label>
                  <select
                    value={basicForm?.religion}
                    className="form-select"
                    onChange={(e) =>
                      setBasicForm({
                        ...basicForm,
                        religion: e.target.value,
                      })
                    }
                  >
                    <option value="">Select</option>
                    {religionList?.map((item, index) => (
                      <option value={item?.religion} key={index}>
                        {item?.religion}
                      </option>
                    ))}
                  </select>
                </FormGroup> */}
                <FormGroup className="col-sm-6">
                  <Label>Preferred Job Type</Label>
                  <select
                    value={basicForm?.work_type}
                    className="form-select"
                    onChange={(e) => {
                      setBasicForm({
                        ...basicForm,
                        work_type: e.target.value,
                      });
                      setErrorJobType("");
                    }}
                  >
                    <option value="">Select</option>
                    {employmentTypeList?.map((item, index) => {
                      return (
                        <option key={index} value={item.name}>
                          {item.name?.split("-")?.length
                            ? item.name?.split("-").join(" ")
                            : item.name}
                        </option>
                      );
                    })}
                  </select>
                  {errorJobType && (
                    <label className="label-input100 text-danger">
                      {errorJobType}
                    </label>
                  )}
                </FormGroup>
              </div>
              {isProfilePage ? (
                <div className="text-center mt-4">
                  <div className="btn btn-blue" onClick={() => handleSave()}>
                    Save
                  </div>
                </div>
              ) : (
                <div className="text-end my-4 d-flex justify-content-between align-items-center">
                  {agency ? (
                    <Button
                      className="btn-outline no-bg"
                      onClick={() => setActivePage("")}
                    >
                      Back
                    </Button>
                  ) : !location?.state?.fromNP ? (
                    <Button
                      className="btn-outline no-bg"
                      onClick={() => navigate("/worker/account-type")}
                    >
                      Back
                    </Button>
                  ) : (
                    <Button
                      className="btn-outline no-bg"
                      onClick={() => navigate("/")}
                    >
                      Back
                    </Button>
                  )}
                  <Button
                    type="submit"
                    className="btn-blue mt-0 w-auto"
                    onClick={(e) => handleContinue(e)}
                  >
                    Save & Continue
                  </Button>
                </div>
              )}
            </form>
          </div>
        </div>
        {askNobelModal && (
          <AskNobePage
            modal={askNobelModal}
            toggle={() => setAskNobelModal(!askNobelModal)}
          />
        )}
      </div>
    </>
  );
};

export default BasicInformation;
