import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import {
//   getAds,
//   postViewLog,
// } from "../../../../store/dashboard/dashboardActions";
import "./AdBar.scss";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from "reactstrap";
import { getAds } from "../../../store/dashboard/dashboardAction";
import { Link } from "react-router-dom";

export default function AdBar() {
  const dispatch = useDispatch();
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [toggleMenu, setToggleMenu] = useState(false);
  const { ads } = useSelector((state) => state.dashboard);
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === ads.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? ads.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  useEffect(() => {
    dispatch(getAds())
      .then((res) => {})
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="mb-3">
      {ads?.length > 0 && (
        <div className="advertisement-side">
          <div
            className="bg-white"
            style={{ borderRadius: "5px", position: "relative" }}
          >
            <div className="row p-1">
              <div className="col-6">
                <h6>Ad</h6>
              </div>
            </div>

            <div>
              <Carousel
                activeIndex={activeIndex}
                next={next}
                previous={previous}
              >
                <CarouselIndicators
                  items={ads}
                  activeIndex={activeIndex}
                  onClickHandler={goToIndex}
                />
                {ads.map((item) => (
                  <CarouselItem
                    className="custom-tag"
                    tag="div"
                    key={item.key}
                    onExiting={() => setAnimating(true)}
                    onExited={() => setAnimating(false)}
                  >
                    {console.log(item)}
                    <div
                      style={{
                        background: `url(${item?.Feed_Documents[0]?.document_url}) no-repeat`,
                        backgroundSize: "cover",
                        height: "232px",
                      }}
                    >
                      <div className="ad-black-shade">
                        <div className="ad-content mx-2">
                          <p className="ad-desc">
                            {item?.campaignDetails?.name}
                          </p>
                          <p className="ad-link">
                            <Link
                              className="text-light"
                              to={item?.campaignDetails?.action_link}
                              target="_blank"
                            >
                              {item?.campaignDetails?.action_link_label ||
                                "Label"}
                            </Link>
                          </p>
                        </div>
                      </div>
                    </div>
                  </CarouselItem>
                ))}
                <CarouselControl
                  direction="prev"
                  directionText="Previous"
                  onClickHandler={previous}
                />
                <CarouselControl
                  direction="next"
                  directionText="Next"
                  onClickHandler={next}
                />
              </Carousel>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
