import React, { useState } from "react";
import profile from "../../../assets/img/profile1.png";
import "../../../assets/styles/style.scss";
import { jobApi } from "../../../api/jobApi";
import JobPaymentModel from "../../JobPaymentModel/JobPaymentModel";
import JobPaymentSuccessModel from "../../JobPaymentModel/PaymentSuccessModel";

const JobPreview = ({ setExpData, data, setCon, user, currencyCodes }) => {
  const [isOpen, setIsModelOpen] = useState(false);
  const [successModel, setSuccessModel] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();

    setIsModelOpen(true);
  };

  return (
    <>
      <div className="job-preview-page workerpool-default-form-page">
        <div className="container">
          <div className="width-850" style={{ margin: "auto" }}>
            <div className="head">
              <span className="head-title">Job Preview</span>
              <h2>{data?.title}</h2>
              <h4>{data?.company_name}</h4>
            </div>
            <h5>Job Description:</h5>
            <p className="mb-2" style={{ color: "#464646" }}>
              {data?.description}
            </p>
            <div class="row">
              <div class="col-4 col-sm-4 mt-3 d-flex justify-content-between">
                <h6>Job Location</h6> :
              </div>
              <div class="col-8 col-sm-8 mt-3">
                <span class="edit-text"> {data?.job_location} </span>
              </div>
              <div class="col-4 col-sm-4 mt-3 d-flex justify-content-between">
                <h6>Category</h6> :
              </div>
              <div class="col-8 col-sm-8 mt-3">
                <span class="edit-text"> {data?.profession} </span>
              </div>
              <div class="col-4 col-sm-4 mt-3 d-flex justify-content-between">
                <h6>Job Type</h6> :
              </div>
              <div class="col-8 col-sm-8 mt-3">
                <span class="edit-text"> {data?.employment_type}</span>
              </div>
              <div class="col-4 col-sm-4 mt-3 d-flex justify-content-between">
                <h6>Gender Type</h6> :
              </div>
              <div class="col-8 col-sm-8 mt-3">
                <span class="edit-text"> {data?.gender} </span>
              </div>
              <div class="col-4 col-sm-4 mt-3 d-flex justify-content-between">
                <h6>Experience</h6> :
              </div>
              <div class="col-8 col-sm-8 mt-3">
                <span class="edit-text">
                  {" "}
                  {data?.min_experience} - {data?.max_experience}{" "}
                  {data?.max_experience > 1 ? "years" : "year"}
                </span>
              </div>
              <div class="col-4 col-sm-4 mt-3 d-flex justify-content-between">
                <h6>Age</h6> :
              </div>
              <div class="col-8 col-sm-8 mt-3">
                <span class="edit-text"> {data?.age} </span>
              </div>
              <div class="col-4 col-sm-4 mt-3 d-flex justify-content-between">
                <h6>Salary</h6> :
              </div>
              <div class="col-8 col-sm-8 mt-3">
                <span class="edit-text">
                  {" "}
                  {data?.min_salary} - {data?.max_salary} {data?.salary_type}{" "}
                </span>
              </div>
              <div class="col-4 col-sm-4 mt-3 d-flex justify-content-between">
                <h6>Looking From</h6> :
              </div>
              <div class="col-8 col-sm-8 mt-3">
                <span class="edit-text"> {data?.nationality?.join(", ")} </span>
              </div>
              <div class="col-4 col-sm-4 mt-3 d-flex justify-content-between">
                <h6>Religion</h6> :
              </div>
              <div class="col-8 col-sm-8 mt-3">
                <span class="edit-text"> {data?.religion} </span>
              </div>
            </div>
            {data?.contact_detail && (
              <div className="d-flex justify-content-between align-items-center poster-box">
                <div className="d-flex align-items-center">
                  <img
                    src={user?.profile_img || profile}
                    alt="Image"
                    height={70}
                    width={70}
                  />
                  <h3>
                    {user?.first_name}
                    {user?.last_name}
                  </h3>
                </div>
                <span className="right-txt">Job Poster</span>
              </div>
            )}

            <div className="text-center mt-5">
              <button
                className="btn btn-blue px-5 mb-4"
                onClick={(e) => handleSubmit(e)}
              >
                Post
              </button>
            </div>
          </div>
        </div>
      </div>

      <JobPaymentModel
        isOpen={isOpen}
        setIsModelOpen={setIsModelOpen}
        setSuccessModel={setSuccessModel}
        data={data}
        setExpData={setExpData}
        currencyCodes={currencyCodes}
      />

      {successModel && (
        <JobPaymentSuccessModel
          setSuccessModel={setSuccessModel}
          successModel={successModel}
        />
      )}
    </>
  );
};

export default JobPreview;
