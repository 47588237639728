import React, { useEffect, useState } from "react";
import backArrow from "../../assets/img/backArrow.svg";
import { useNavigate, useParams } from "react-router-dom";
import "./ManageWorker.scss";
import { useDispatch, useSelector } from "react-redux";
import { getCompanyWorker } from "../../store/provider/ProviderAction";
import DefaultIcon from "../../assets/img/default-icon-Profile.png";
import EditIcon from "../../assets/img/blackedit.svg";
import PaymentModel from "../paymentModel/PaymentModel";
import { Input } from "reactstrap";
import PaymentSuccessModel from "../paymentModel/PaymentSuccessModel";
import BasicInformationContainer from "../WorkerPoolFormPages/BasicInformation/BasicInformationContainer";
import Header from "../../core/headers/Header";
import { loadUser } from "../../store/auth/authActions";
import { getWPUserProfileById } from "../../store/profile/ProfileAction";
import WorkerPoolUserProfileContainer from "../WorkerPoolProfilePages/WorkerPoolUserProfileContainer";
const ManageWorker = () => {
  const navigate = useNavigate();
  const [limit, setLimit] = useState(100);
  const [skip, setSkip] = useState(0);
  const [search, setSearch] = useState("");
  const { manageWorkers } = useSelector((state) => state.provider);
  const [action, setAction] = useState("");
  const [successModel, setSuccessModel] = useState(false);

  const { user, isLoggedIn } = useSelector((state) => state.auth);
  const { nobelprofileData } = useSelector((state) => state.profile);
  const dispatch = useDispatch();
  const params = useParams();
  const token = localStorage.getItem("accessToken");
  const [activePage, setActivePage] = useState("");
  const [agency, setAgency] = useState(params?.id || 0);

  useEffect(() => {
    if (params?.id) setAgency(params?.id);
  }, []);

  function remainingTime(dateString) {
    var today = new Date();
    var publishDate = new Date(dateString);
    const diffTime = Math.abs(publishDate - today);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    // var timeLeft = 30 -diffDays;
    // console.log("timeLeft",diffDays);
    return diffDays;
  }
  useEffect(() => {
    if (isLoggedIn) {
      dispatch(loadUser());
    }
  }, []);
  useEffect(() => {
    if (!workerId) dispatch(getWPUserProfileById(user?.worker?.id, token));
  }, [user]);

  const [workerId, setWorkerId] = useState(null);

  useEffect(() => {
    if (!activePage)
      dispatch(getCompanyWorker({ agency_id: agency, limit, skip }));
  }, [agency, activePage]);

  function remainingTime(dateString) {
    var today = new Date();
    var publishDate = new Date(dateString);
    const diffTime = Math.abs(publishDate - today);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    // var timeLeft = 30 -diffDays;
    // console.log("timeLeft",diffDays);
    return diffDays;
  }
  const [selectedRows, setSelectedRows] = useState([]);
  const [isModelOpen, setIsModelOpen] = useState(false);

  const handleRowClick = (rowId) => {
    const isSelected = selectedRows.includes(rowId);

    if (isSelected) {
      // If the row is already selected, remove it from the selection
      setSelectedRows(selectedRows.filter((id) => id !== rowId));
    } else {
      // If the row is not selected, add it to the selection
      setSelectedRows([...selectedRows, rowId]);
    }
  };

  const handleCheckboxClick = () => {
    selectedRows.length == manageWorkers.length
      ? setSelectedRows([])
      : setSelectedRows(manageWorkers?.map((worker) => worker.id));
  };

  const searchWorker = (e) => {
    setSearch(e.target.value);
    if (e.target.value.length >= 3) {
      dispatch(
        getCompanyWorker({
          search: e.target.value,
          agency_id: agency,
          limit,
          skip,
        })
      );
    } else if (!e.target.value?.length) {
      dispatch(getCompanyWorker({ agency_id: agency, limit, skip }));
    }
  };

  return (
    <>
      <Header isProfilePage={true} user={user} />
      <div className="">
        <div className="container">
          {!["addWorker", "editWorker", "viewWorker"].includes(activePage) && (
            <div className="workerpool-default-form-page">
              <div
                className="back-btn d-flex align-items-center gap-2 mb-4"
                onClick={() => navigate(-1)}
              >
                <img src={backArrow} alt="Icon" />
                <h2 className="m-0">Manage Workers</h2>
              </div>
            </div>
          )}

          {activePage == "addWorker" ? (
            <BasicInformationContainer
              agency={agency}
              setActivePage={setActivePage}
            />
          ) : activePage == "viewWorker" ? (
            <WorkerPoolUserProfileContainer
              workerId={workerId}
              setActivePage={setActivePage}
            />
          ) : activePage == "editWorker" ? (
            <WorkerPoolUserProfileContainer
              workerId={workerId}
              openEdit={true}
              setActivePage={setActivePage}
            />
          ) : (
            <>
              <div className="">
                <div className="d-flex justify-content-between">
                  <div className="">
                    <Input
                      type="text"
                      name="search"
                      value={search}
                      placeholder="Search"
                      onChange={(e) => searchWorker(e)}
                    />
                  </div>
                  <div className="d-flex gap-3 align-items-center justify-content-between">
                    {selectedRows?.length > 0 ? (
                      <>
                        <button
                          className="public_btn py-2 px-4 bg-transparent"
                          onClick={() => {
                            setIsModelOpen(!isModelOpen);
                            setAction("public");
                          }}
                        >
                          Make Public
                        </button>
                        <button
                          className="delete_btn py-2 px-4 manage bg-transparent"
                          onClick={() => {
                            setIsModelOpen(!isModelOpen);
                            setAction("delete");
                          }}
                        >
                          Delete
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          className="add_btn py-2 px-4 manage"
                          onClick={() =>
                            // navigate("/worker/basic-info", {
                            //   state: {
                            //     id,
                            //   },
                            // })
                            setActivePage("addWorker")
                          }
                        >
                          Add Employee
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>

              {!manageWorkers?.length ? (
                <>
                  <div className="mt-4 d-flex justify-content-center">
                    <h2 className="">No Data Found</h2>
                  </div>
                </>
              ) : (
                <>
                  <div className="mt-5 pb-5 table_style ">
                    <table className="w-100 p-5">
                      <thead>
                        <tr>
                          <th>
                            <input
                              type="checkbox"
                              className="checkbox"
                              checked={
                                selectedRows?.length == manageWorkers?.length
                              }
                              onClick={handleCheckboxClick}
                            />
                          </th>
                          <th>Employee Name</th>
                          <th>Profession</th>
                          <th>Location</th>
                          <th>Contact</th>
                          <th>Status</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {manageWorkers?.map((ele, index) => {
                          return (
                            <>
                              <tr
                                className="p-3"
                                style={{
                                  background: selectedRows.includes(ele.id)
                                    ? "#EDF2FF"
                                    : "transparent",
                                }}
                                key={ele.id}
                              >
                                <td>
                                  <input
                                    type="checkbox"
                                    className="checkbox"
                                    checked={selectedRows.includes(ele.id)}
                                    onClick={() => handleRowClick(ele.id)}
                                  />
                                </td>
                                <td>
                                  <div
                                    className="d-flex align-items-center"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      setWorkerId(ele?.id);
                                      setActivePage("viewWorker");
                                    }}
                                  >
                                    <img
                                      src={
                                        ele?.user?.profile_img || DefaultIcon
                                      }
                                      className="image_icon"
                                    />
                                    <p
                                      className="name mx-3 card-control-text-oneline"
                                      title={`${ele?.user?.first_name} ${ele?.user?.last_name}`}
                                    >
                                      {ele?.user?.first_name}{" "}
                                      {ele?.user?.last_name}
                                    </p>
                                  </div>
                                </td>
                                <td>
                                  <h6
                                    className="card-control-text-oneline"
                                    title={`${ele?.profession.join(", ")}`}
                                  >
                                    {ele?.profession.join(", ") || "headline"}
                                  </h6>
                                </td>
                                <td>{ele?.desire_country || "location"}</td>
                                <td>{ele?.user?.phone || "phone"}</td>
                                <td>
                                  {ele?.is_public ? (
                                    <>
                                      <div className="">
                                        <p className="public">Public</p>
                                        <p className="public_date">
                                          {ele?.is_public_date
                                            ? remainingTime(ele?.is_public_date)
                                            : "30"}{" "}
                                          days remaining
                                        </p>
                                      </div>
                                    </>
                                  ) : (
                                    <p>Private</p>
                                  )}
                                </td>
                                <td>
                                  <img
                                    src={EditIcon}
                                    onClick={() => {
                                      setWorkerId(ele?.id);
                                      setActivePage("editWorker");
                                    }}
                                  />
                                </td>
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>

      {isModelOpen && (
        <PaymentModel
          worker={manageWorkers}
          selectedWorker={selectedRows}
          isOpen={isModelOpen}
          setIsModelOpen={setIsModelOpen}
          action={action}
          setSelectedRows={setSelectedRows}
          successModel={successModel}
          setSuccessModel={setSuccessModel}
        />
      )}

      {successModel && (
        <PaymentSuccessModel
          successModel={successModel}
          setSuccessModel={setSuccessModel}
        />
      )}
    </>
  );
};

export default ManageWorker;
