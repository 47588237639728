import React, { useEffect } from "react";
import "./WorkerPoolProfile.scss";
import userImg from "../../assets/img/user-img.png";
import edit from "../../assets/img/blueedit.svg";
import smallLogo from "../../assets/img/logo.svg";
import plusBlue from "../../assets/img/plus-blue.svg";
import playIcon from "../../assets/img/playIcon.png";
import placeholderUser from "../../assets/img/blueavatar.svg";
import Sidebar from "./Componenets/Sidebar";
import Header from "../../core/headers/Header";
import moment from "moment";
import AddIntroVideo from "./Componenets/AddVideoModal";
import ProfilePhotoModal from "./Componenets/ProfilePhotoModal";
import BasicInformation from "../WorkerPoolFormPages/BasicInformation/BasicInformation";
import Education from "../WorkerPoolFormPages/Education/Education";
import WorkExperience from "../WorkerPoolFormPages/WorkExperience/WorkExperience";
import ProfileResume from "./Resume/Resume";
import ProfileSetting from "./Settings/Settings";
import PublishYourProfile from "./Componenets/PublishYourProfile";
import Licence from "../WorkerPoolFormPages/Licence/Licence";
import Languages from "../WorkerPoolFormPages/Languages/Languages";
import PublishPaymentModal from "./Componenets/PublishPaymentModal";
import { ToastContainer } from "react-toastify";
import ChangePassword from "./ChangePassword/ChangePassword";
import EditInfo from "./Componenets/EditInfo";
import AppLoader from "../../core/Loaders/appLoader/appLoader";
import { useSelector } from "react-redux";
import MyJobs from "./MyJobs/MyJobs";
import PhoneImage from "../../assets/img/blackphone.svg";
import EmailImage from "../../assets/img/blackemail.svg";
import WhatsappImage from "../../assets/img/blackwhatsapp.svg";
import BlackIcon from "../../assets/img/blackicon.svg";
import backArrow from "../../assets/img/backArrow.svg";
import BusinessPage from "../WorkerPoolFormPages/AddBusinessPage/BusinessPage";
import ViewApplicant from "./ViewApplicant/ViewApplicant";
import { useLocation, useNavigate } from "react-router-dom";
const WorkerPoolUserProfile = (props) => {
  const {
    user,
    isSeeMore,
    toggleSeeMore,
    nobelprofileData,
    addIntroModal,
    setAddIntroModal,
    videoUrl,
    age,
    profilePhotoModal,
    setProfilePhotoModal,
    handleRemovePicture,
    handleBannerPicture,
    profileField,
    setProfileField,

    errorEmail,
    setErrorEmail,
    errorFirstName,
    setErrorFirstName,
    errorLastName,
    setErrorLastName,
    setErrorPassword,
    errorPhoneNumber,
    errorMessage,
    setPhoneCount,
    checkSignUpValidation,
    basicForm,
    setBasicForm,
    errorPassword,
    submitSignUpData,
    // navigate,
    today,
    errorWhatsappNumber,
    countryList,
    experienceRegion,
    religionList,
    employmentTypeList,
    errorAbout,
    setErrorAbout,
    errorHeadline,
    setErrorHeadline,
    errorDob,
    setErrorDob,
    errorExp,
    setErrorExp,
    errorPrefCountry,
    setErrorPrefCountry,
    errorNationality,
    setErrorNationality,
    errorROE,
    setErrorROE,
    errorJobType,
    setErrorJobType,
    getRegionOfExperience,
    handleFormSubmit,
    publishModal,
    setPublishModal,
    remainingTime,
    paymentModal,
    setPaymentModal,
    catergoryList,
    professionList,
    selectedCategory,
    setSelectedCategory,
    errorCategory,
    setErrorCategory,
    errorProfession,
    setErrorProfession,
    handleProfession,
    selectJob,
    setSelectedJob,
    errorGender,
    setErrorGender,
    workerId,
    setActivePage,
  } = props;
  const { isPageLoading } = useSelector((state) => state.loader);
  const navigate = useNavigate();

  return (
    <>
      {isPageLoading && <AppLoader />}
      {!workerId && (
        <Header
          isProfilePage={true}
          user={nobelprofileData?.user}
          setProfileField={setProfileField}
        />
      )}
      <>
        <div className="profile-dashboard-main">
          <div className="profile-container">
            <div className="row">
              {!workerId && (
                <div className="col-xxl-3 col-lg-2 col-12">
                  <Sidebar
                    setProfileField={setProfileField}
                    profileField={profileField}
                  />
                </div>
              )}
              <div
                className={workerId ? "col-12" : "col-xxl-9 col-lg-10 col-12"}
              >
                {profileField === "profile" ? (
                  nobelprofileData?.type == "worker" ? (
                    <div
                      className={
                        workerId
                          ? "profile-content-outer ps-0 pt-2"
                          : "profile-content-outer"
                      }
                    >
                      {workerId && (
                        <div className="workerpool-default-form-page">
                          <div
                            className="back-btn d-flex align-items-center gap-2 mt-4"
                            onClick={() => setActivePage("")}
                          >
                            <img src={backArrow} alt="Icon" />
                            <h6 className="m-0">Back</h6>
                          </div>
                        </div>
                      )}
                      <div className="d-flex justify-content-between">
                        <div className="row">
                          <div className="col-sm-4">
                            <div className="profile-box d-flex">
                              <div className="position-relative">
                                <img
                                  className={
                                    nobelprofileData?.user?.profile_img
                                      ? "rounded-circle"
                                      : "p-5 rounded-circle"
                                  }
                                  src={
                                    nobelprofileData?.user?.profile_img
                                      ? nobelprofileData?.user?.profile_img
                                      : placeholderUser
                                  }
                                  alt="Icon"
                                  height={250}
                                  width={250}
                                />
                                <div className="edit-btn">
                                  <img
                                    className="p-2"
                                    src={edit}
                                    width={40}
                                    alt="Icon"
                                    onClick={() =>
                                      setProfilePhotoModal(!profilePhotoModal)
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="profile-content-box col-sm-8">
                            <div className="d-flex justify-content-between flex-wrap">
                              <div className="head">
                                <h2>
                                  {nobelprofileData?.user?.first_name}{" "}
                                  {nobelprofileData?.user?.last_name}
                                </h2>
                                <h5>
                                  {nobelprofileData?.profession?.map(
                                    (item, index) => (
                                      <p style={{ marginTop: "8px" }}>
                                        ● {item?.profession}{" "}
                                      </p>
                                    )
                                  )}
                                </h5>
                              </div>
                              <div className="right-outer d-flex align-items-start justify-content-end  gap-3">
                                {nobelprofileData?.user?.np ? (
                                  <div
                                    className="profile-logo d-flex"
                                    onClick={() =>
                                      window.open(
                                        `${process.env.REACT_APP_WEB_STAGE_URL}/profile/${user?.id}`
                                      )
                                    }
                                  >
                                    <img
                                      src={smallLogo}
                                      alt="Icon"
                                      width={20}
                                    />
                                    <div className="profile_logo_text">
                                      {nobelprofileData?.user?.first_name}{" "}
                                      {nobelprofileData?.user?.last_name}
                                    </div>
                                  </div>
                                ) : (
                                  <div
                                    className="profile-logo"
                                    onClick={() =>
                                      window.open(
                                        `${process.env.REACT_APP_WEB_STAGE_URL}/signup`
                                      )
                                    }
                                  >
                                    <img
                                      src={smallLogo}
                                      alt="Icon"
                                      width={20}
                                    />
                                    Connect
                                  </div>
                                )}
                                <span
                                  className={
                                    nobelprofileData?.is_public
                                      ? "light-green"
                                      : "light-blue"
                                  }
                                >
                                  {nobelprofileData?.is_public
                                    ? "Public Mode"
                                    : "Private Mode"}
                                </span>
                              </div>
                            </div>
                            <p>
                              {isSeeMore == true
                                ? nobelprofileData?.user?.profile?.about?.slice(
                                    0,
                                    150
                                  )
                                : nobelprofileData?.user?.profile?.about}
                              {nobelprofileData?.user?.profile?.about?.length >
                              150 ? (
                                <span className="see" onClick={toggleSeeMore}>
                                  {isSeeMore == true
                                    ? "...See more"
                                    : " See less"}
                                </span>
                              ) : (
                                <></>
                              )}
                            </p>
                            {!nobelprofileData?.is_public ? (
                              <button
                                className="grdient-btn"
                                onClick={() => setPublishModal(true)}
                              >
                                Switch to Public Mode
                              </button>
                            ) : (
                              <span className="remaining-time">
                                {nobelprofileData?.is_public_date
                                  ? remainingTime(
                                      nobelprofileData?.is_public_date
                                    )
                                  : "30"}{" "}
                                days remaining
                              </span>
                            )}
                            {!nobelprofileData?.is_public ? (
                              <p className="mt-2">
                                Activate public mode for 30 days to boost
                                visibility.
                              </p>
                            ) : (
                              <p className="mt-2">
                                Requires manual renewal to become public again.
                              </p>
                            )}
                          </div>
                          <div className="row flex-sm-row flex-column-reverse">
                            <div className="col-sm-4">
                              <div className="editable-box-outer">
                                <div className="editable-box">
                                  <div className="ed-head d-flex justify-content-between">
                                    <span className="ed-title">
                                      Work Experience
                                    </span>
                                    <span
                                      className="edit"
                                      onClick={() =>
                                        setProfileField("experience")
                                      }
                                    >
                                      {nobelprofileData?.user?.experiences
                                        ?.length > 0
                                        ? "Edit"
                                        : "Add"}
                                    </span>
                                  </div>

                                  {nobelprofileData?.user?.experiences?.map(
                                    (item, index) => (
                                      <div key={index}>
                                        {item?.title?.length > 30 ? (
                                          <h3>
                                            {item?.title?.slice(0, 30)}...
                                          </h3>
                                        ) : (
                                          <h3>{item?.title}</h3>
                                        )}
                                        {item?.company_name?.length > 30 ? (
                                          <h6>
                                            {item?.company_name?.slice(0, 30)}
                                            ...
                                          </h6>
                                        ) : (
                                          <h6>{item?.company_name}</h6>
                                        )}
                                        <div
                                          className="location"
                                          style={{ fontSize: "16px" }}
                                        >
                                          {item?.profession}
                                        </div>
                                        <div
                                          className="location"
                                          style={{ fontSize: "16px" }}
                                        >
                                          <div>
                                            {moment(item?.start_date)
                                              ?.utc()
                                              ?.format("MMM YYYY")}
                                            {" - "}{" "}
                                            {item?.is_current
                                              ? "Present"
                                              : item?.end_date
                                              ? moment(item?.end_date)
                                                  ?.utc()
                                                  ?.format("MMM YYYY")
                                              : "No Date"}
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  )}
                                  {nobelprofileData?.user?.experiences
                                    ?.length <= 0 && (
                                    <h6>No Experience Added</h6>
                                  )}
                                </div>
                              </div>

                              <div className="editable-box-outer">
                                <div className="editable-box">
                                  <div className="ed-head d-flex justify-content-between">
                                    <span className="ed-title">Education</span>
                                    <span
                                      className="edit"
                                      onClick={() =>
                                        setProfileField("education")
                                      }
                                    >
                                      {nobelprofileData?.user?.educations
                                        ?.length > 0
                                        ? "Edit"
                                        : "Add"}
                                    </span>
                                  </div>
                                  {nobelprofileData?.user?.educations?.map(
                                    (item, index) => (
                                      <div key={index}>
                                        {item?.degree?.length > 30 ? (
                                          <h3>
                                            {item?.degree?.slice(0, 30)}...
                                          </h3>
                                        ) : (
                                          <h3>{item?.degree}</h3>
                                        )}
                                        {item?.school_name?.length > 30 ? (
                                          <h6>
                                            {item?.school_name?.slice(0, 30)}...
                                          </h6>
                                        ) : (
                                          <h6>{item?.school_name}</h6>
                                        )}
                                        <div className="location">
                                          {item?.field_of_study}
                                        </div>
                                      </div>
                                    )
                                  )}
                                  {nobelprofileData?.user?.educations?.length <=
                                    0 && <h6>No Education Added</h6>}
                                </div>
                              </div>
                              <div className="editable-box-outer">
                                <div className="editable-box">
                                  <div className="ed-head d-flex justify-content-between">
                                    <span className="ed-title">Licence</span>
                                    <span
                                      className="edit"
                                      onClick={() => setProfileField("licence")}
                                    >
                                      {nobelprofileData?.user
                                        ?.license_certifications?.length > 0
                                        ? "Edit"
                                        : "Add"}
                                    </span>
                                  </div>
                                  {nobelprofileData?.user?.license_certifications?.map(
                                    (item, index) => (
                                      <div key={index}>
                                        {item?.name?.length > 30 ? (
                                          <h3>{item?.name?.slice(0, 30)}...</h3>
                                        ) : (
                                          <h3>{item?.name}</h3>
                                        )}
                                        <div className="d-flex justify-content-between align-items-center">
                                          <p style={{ fontSize: "16px" }}>
                                            Valid:{" "}
                                            {new Date(
                                              item?.end_date
                                            ).toLocaleString("en-us", {
                                              month: "short",
                                              year: "numeric",
                                            })}
                                          </p>
                                          <p style={{ fontSize: "16px" }}>
                                            Issued:{" "}
                                            {new Date(
                                              item?.start_date
                                            ).toLocaleString("en-us", {
                                              month: "short",
                                              year: "numeric",
                                            })}
                                          </p>
                                        </div>
                                      </div>
                                    )
                                  )}
                                  {nobelprofileData?.user
                                    ?.license_certifications?.length <= 0 && (
                                    <h6>No Licence Added</h6>
                                  )}
                                </div>
                              </div>
                              <div className="editable-box-outer">
                                <div className="editable-box">
                                  <div className="ed-head d-flex justify-content-between">
                                    <span className="ed-title">Language</span>
                                    <span
                                      className="edit"
                                      onClick={() =>
                                        setProfileField("language")
                                      }
                                    >
                                      {nobelprofileData?.user?.languages
                                        ?.length > 0
                                        ? "Edit"
                                        : "Add"}
                                    </span>
                                  </div>
                                  {nobelprofileData?.user?.languages?.map(
                                    (item, index) => (
                                      <div key={index}>
                                        <h3>{item?.name}</h3>
                                        <p style={{ fontSize: "16px" }}>
                                          {item?.proficiency}
                                        </p>
                                      </div>
                                    )
                                  )}
                                  {nobelprofileData?.user?.languages?.length <=
                                    0 && <h6>No Language Added</h6>}
                                </div>
                              </div>
                            </div>
                            <div className="profile-content-box col-sm-8">
                              <div className="editable-box-outer profile-info-box">
                                <div className="editable-box">
                                  <div className="ed-head profile d-flex justify-content-between">
                                    <span className="ed-title">
                                      Profile Info
                                    </span>
                                    <span
                                      className="edit"
                                      onClick={() =>
                                        setProfileField("basic-info")
                                      }
                                    >
                                      Edit
                                    </span>
                                  </div>
                                  <div className="row">
                                    <div className="col-sm-12 d-flex">
                                      <div className="col-sm-6 d-flex justify-content-between gap-2">
                                        <h6>Intro Video</h6> :
                                      </div>
                                      <div className="col-sm-6">
                                        {!nobelprofileData?.user?.profile
                                          ?.intro_video ? (
                                          <span
                                            className="edit-text blue ms-3"
                                            onClick={() =>
                                              setAddIntroModal(true)
                                            }
                                          >
                                            <img src={plusBlue} alt="Icon" />
                                            Add Introduction video
                                          </span>
                                        ) : (
                                          <span
                                            className="edit-text blue ms-3"
                                            onClick={() =>
                                              setAddIntroModal(true)
                                            }
                                          >
                                            <img src={playIcon} alt="Icon" />{" "}
                                            Play Video
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                    {age && (
                                      <div className="col-sm-12 d-flex">
                                        <div className="col-sm-6 mt-4 d-flex justify-content-between">
                                          <h6>Age</h6> :
                                        </div>
                                        <div className="col-sm-6 mt-4 ms-4 ">
                                          <span className="edit-text">
                                            {age}
                                          </span>
                                        </div>
                                      </div>
                                    )}

                                    {nobelprofileData?.user?.profile
                                      ?.gender && (
                                      <div className="col-sm-12 d-flex">
                                        <div className="col-sm-6 mt-4 d-flex justify-content-between">
                                          <h6>Gender</h6> :
                                        </div>
                                        <div className="col-sm-6 mt-4 ms-4">
                                          <span className="edit-text text-capitalize">
                                            {
                                              nobelprofileData?.user?.profile
                                                ?.gender
                                            }
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                    {nobelprofileData?.experience >= 0 && (
                                      <div className="col-sm-12 d-flex">
                                        <div className="col-sm-6 mt-4 d-flex justify-content-between">
                                          <h6>Experience</h6> :
                                        </div>
                                        <div className="col-sm-6 mt-4 ms-4">
                                          <span className="edit-text">
                                            {nobelprofileData?.experience == 0
                                              ? "Fresher"
                                              : nobelprofileData?.experience > 1
                                              ? nobelprofileData?.experience +
                                                " years"
                                              : nobelprofileData?.experience +
                                                " year"}
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                    {nobelprofileData?.user?.profile
                                      ?.nationality?.name && (
                                      <div className="col-sm-12 d-flex">
                                        <div className="col-sm-6 mt-4 d-flex justify-content-between">
                                          <h6>Nationality</h6> :
                                        </div>
                                        <div className="col-sm-6 mt-4 ms-4">
                                          <span className="edit-text">
                                            {
                                              nobelprofileData?.user?.profile
                                                ?.nationality?.name
                                            }{" "}
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                    {nobelprofileData?.desire_country && (
                                      <div className="col-sm-12 d-flex">
                                        <div className="col-sm-6 mt-4 d-flex justify-content-between">
                                          <h6>Preferred Country</h6> :
                                        </div>
                                        <div className="col-sm-6 mt-4 ms-4">
                                          <span className="edit-text">
                                            {nobelprofileData?.desire_country}
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                    {nobelprofileData?.experienceRegion
                                      .region && (
                                      <div className="col-sm-12 d-flex">
                                        <div className="col-sm-6 mt-4 d-flex justify-content-between">
                                          <h6>Region of Experience</h6> :
                                        </div>
                                        <div className="col-sm-6 mt-4 ms-4">
                                          <span className="edit-text">
                                            {
                                              nobelprofileData?.experienceRegion
                                                .region
                                            }
                                          </span>
                                        </div>
                                      </div>
                                    )}

                                    {nobelprofileData?.work_type && (
                                      <div className="col-sm-12 d-flex">
                                        <div className="col-sm-6 mt-4 d-flex justify-content-between">
                                          <h6>Preferred Job Type</h6> :
                                        </div>
                                        <div className="col-sm-6 mt-4 ms-4">
                                          <span className="edit-text text-capitalize">
                                            {nobelprofileData?.work_type?.split(
                                              "-"
                                            )?.length
                                              ? nobelprofileData?.work_type
                                                  ?.split("-")
                                                  .join(" ")
                                              : nobelprofileData?.work_type}
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                    {nobelprofileData?.religion && (
                                      <div className="col-sm-12 d-flex">
                                        <div className="col-sm-6 mt-4 d-flex justify-content-between">
                                          <h6>Religion</h6> :
                                        </div>
                                        <div className="col-sm-6 mt-4 ms-4">
                                          <span className="edit-text">
                                            {nobelprofileData?.religion}
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="profile-content-outer">
                      <div className="">
                        <div className="row">
                          <div className="col-sm-4">
                            <div className="profile-box d-flex">
                              <div className="position-relative">
                                <img
                                  className={
                                    nobelprofileData?.user?.profile_img
                                      ? "rounded-circle"
                                      : "p-5 rounded-circle"
                                  }
                                  src={
                                    nobelprofileData?.user?.profile_img
                                      ? nobelprofileData?.user?.profile_img
                                      : placeholderUser
                                  }
                                  alt="Icon"
                                  height={250}
                                  width={250}
                                />
                                <div className="edit-btn">
                                  <img
                                    width={40}
                                    className="p-2"
                                    src={edit}
                                    alt="Icon"
                                    onClick={() =>
                                      setProfilePhotoModal(!profilePhotoModal)
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="profile-content-box col-sm-8">
                            <div className="d-flex justify-content-between flex-wrap">
                              <div className="head">
                                <h2>
                                  {nobelprofileData?.user?.first_name}{" "}
                                  {nobelprofileData?.user?.last_name}
                                </h2>
                                <h5>
                                  {nobelprofileData?.profession?.map(
                                    (item, index) => (
                                      <>{item?.profession} </>
                                    )
                                  )}
                                </h5>
                              </div>
                              <div className="right-outer d-flex justify-content-end">
                                {nobelprofileData?.user?.np ? (
                                  <div
                                    className="profile-logo"
                                    onClick={() =>
                                      window.open(
                                        `${process.env.REACT_APP_WEB_STAGE_URL}/profile/${user?.id}`
                                      )
                                    }
                                  >
                                    <img
                                      src={smallLogo}
                                      alt="Icon"
                                      width={20}
                                    />
                                    {nobelprofileData?.user?.first_name}{" "}
                                    {nobelprofileData?.user?.last_name}
                                  </div>
                                ) : (
                                  <div
                                    className="profile-logo"
                                    onClick={() =>
                                      window.open(
                                        `${process.env.REACT_APP_WEB_STAGE_URL}/signup`
                                      )
                                    }
                                  >
                                    <img
                                      src={smallLogo}
                                      alt="Icon"
                                      width={20}
                                    />
                                    Connect
                                  </div>
                                )}
                                {/* <span
                  className={
                    nobelprofileData?.is_public
                      ? "light-green-bg"
                      : "light-red-bg"
                  }
                >
                  {nobelprofileData?.is_public
                    ? "Public Mode"
                    : "Private Mode"}
                </span> */}
                              </div>
                            </div>
                            <p className="card-control-text-twoline">
                              {isSeeMore == true
                                ? nobelprofileData?.user?.profile?.about?.slice(
                                    0,
                                    400
                                  )
                                : nobelprofileData?.user?.profile?.about}
                              {nobelprofileData?.user?.profile?.about?.length >
                              400 ? (
                                <span className="see" onClick={toggleSeeMore}>
                                  {isSeeMore == true
                                    ? "...See more"
                                    : " See less"}
                                </span>
                              ) : (
                                <></>
                              )}
                            </p>
                            {/* {!nobelprofileData?.is_public ? (
              <button
                className="grdient-btn"
                onClick={() => setPublishModal(true)}
              >
                Publish Your Profile
              </button>
            ) : (
              <span className="remaining-time">
                {nobelprofileData?.is_public_date
                  ? remainingTime(nobelprofileData?.is_public_date)
                  : "30"}{" "}
                days remaining
              </span>
            )} */}

                            <div
                              className="profile_bg p-4"
                              style={{ background: "#F3F3F3" }}
                            >
                              <div className="d-flex justify-content-between  flex-wrap">
                                {nobelprofileData?.user?.phone && (
                                  <div className="d-flex justify-content-center">
                                    <img src={PhoneImage} />
                                    <span className="mx-2">
                                      {nobelprofileData?.user?.phone}
                                    </span>
                                  </div>
                                )}
                                {nobelprofileData?.email && (
                                  <div className="d-flex justify-content-center">
                                    <img src={EmailImage} />
                                    <span className="mx-2">
                                      {nobelprofileData?.email}
                                    </span>
                                  </div>
                                )}
                                {nobelprofileData?.whatsapp && (
                                  <div className="d-flex justify-content-center">
                                    <img src={WhatsappImage} />
                                    <span className="mx-2">
                                      {nobelprofileData?.whatsapp}
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>

                          {nobelprofileData?.type == "provider" && (
                            <div className="mt-5">
                              <div className="d-flex justify-content-between">
                                <h2>Business Page</h2>

                                <span
                                  className="page_edit"
                                  onClick={() =>
                                    setProfileField("add-business-page")
                                  }
                                >
                                  Add Business Page
                                </span>
                              </div>

                              <div className="">
                                {nobelprofileData?.Pages.map((ele, i) => {
                                  return (
                                    <>
                                      <div
                                        className="p-3 mt-3"
                                        style={{
                                          background: "#F3F3F3",
                                          cursor: "pointer",
                                        }}
                                        key={i}
                                        onClick={() => {
                                          setProfileField("manage-worker");
                                          navigate(
                                            `/provider/manage-worker/${ele?.id}`
                                          );
                                        }}
                                      >
                                        <h4>{ele?.name}</h4>
                                        <div className="d-flex justify-content-between mt-3 flex-wrap">
                                          {ele?.phone && (
                                            <div className="d-flex justify-content-center">
                                              <img src={PhoneImage} />
                                              <span className="mx-2 mt-0">
                                                {ele?.phone}
                                              </span>
                                            </div>
                                          )}
                                          {ele?.email && (
                                            <div className="d-flex justify-content-center">
                                              <img src={EmailImage} />
                                              <span className="mx-2 mt-0">
                                                {ele?.email}
                                              </span>
                                            </div>
                                          )}
                                          {ele?.whatsapp && (
                                            <div className="d-flex justify-content-center">
                                              <img src={WhatsappImage} />
                                              <span className="mx-2 mt-0">
                                                {ele?.whatsapp}
                                              </span>
                                            </div>
                                          )}
                                          {ele?.website && (
                                            <div className="d-flex">
                                              <img src={BlackIcon} />
                                              <span className="mx-2 justify-content-center">
                                                {ele?.website}
                                              </span>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )
                ) : profileField === "basic-info" ? (
                  <EditInfo
                    submitSignUpData={submitSignUpData}
                    user={nobelprofileData}
                    handleFormSubmit={handleFormSubmit}
                    checkSignUpValidation={checkSignUpValidation}
                    errorEmail={errorEmail}
                    errorPassword={errorPassword}
                    setErrorPassword={setErrorPassword}
                    setErrorEmail={setErrorEmail}
                    errorFirstName={errorFirstName}
                    setErrorFirstName={setErrorFirstName}
                    errorLastName={errorLastName}
                    setErrorLastName={setErrorLastName}
                    errorPhoneNumber={errorPhoneNumber}
                    errorMessage={errorMessage}
                    setPhoneCount={setPhoneCount}
                    basicForm={basicForm}
                    setBasicForm={setBasicForm}
                    today={today}
                    countryList={countryList}
                    religionList={religionList}
                    employmentTypeList={employmentTypeList}
                    experienceRegion={experienceRegion}
                    errorWhatsappNumber={errorWhatsappNumber}
                    errorAbout={errorAbout}
                    setErrorAbout={setErrorAbout}
                    errorHeadline={errorHeadline}
                    setErrorHeadline={setErrorHeadline}
                    errorDob={errorDob}
                    setErrorDob={setErrorDob}
                    errorExp={errorExp}
                    setErrorExp={setErrorExp}
                    errorPrefCountry={errorPrefCountry}
                    setErrorPrefCountry={setErrorPrefCountry}
                    errorNationality={errorNationality}
                    setErrorNationality={setErrorNationality}
                    errorROE={errorROE}
                    setErrorROE={setErrorROE}
                    errorJobType={errorJobType}
                    setErrorJobType={setErrorJobType}
                    getRegionOfExperience={getRegionOfExperience}
                    selectedCategory={selectedCategory}
                    setSelectedCategory={setSelectedCategory}
                    isProfilePage={true}
                    setProfileField={setProfileField}
                    catergoryList={catergoryList}
                    professionList={professionList}
                    errorCategory={errorCategory}
                    setErrorCategory={setErrorCategory}
                    handleProfession={handleProfession}
                    errorProfession={errorProfession}
                    setErrorProfession={setErrorProfession}
                    errorGender={errorGender}
                    setErrorGender={setErrorGender}
                  />
                ) : profileField === "education" ? (
                  <Education
                    isProfilePage={true}
                    user={nobelprofileData}
                    setProfileField={setProfileField}
                    workerId={workerId}
                  />
                ) : profileField === "experience" ? (
                  <WorkExperience
                    isProfilePage={true}
                    user={nobelprofileData}
                    experienceRegion={experienceRegion}
                    setProfileField={setProfileField}
                    setErrorROE={setErrorROE}
                    catergoryList={catergoryList}
                    professionList={professionList}
                    selectedCategory={selectedCategory}
                    setSelectedCategory={setSelectedCategory}
                    workerId={workerId}
                  />
                ) : profileField === "resume" ? (
                  <ProfileResume
                    setProfileField={setProfileField}
                    nobelprofileData={nobelprofileData}
                  />
                ) : profileField === "setting" ? (
                  <ProfileSetting
                    setProfileField={setProfileField}
                    nobelprofileData={nobelprofileData}
                  />
                ) : profileField === "licence" ? (
                  <Licence
                    user={nobelprofileData}
                    isProfilePage={true}
                    setProfileField={setProfileField}
                    workerId={workerId}
                  />
                ) : profileField === "language" ? (
                  <Languages
                    user={nobelprofileData}
                    isProfilePage={true}
                    setProfileField={setProfileField}
                    workerId={workerId}
                  />
                ) : profileField === "change-password" ? (
                  <ChangePassword
                    // user={nobelprofileData}
                    // isProfilePage={true}
                    setProfileField={setProfileField}
                  />
                ) : profileField === "job" ? (
                  <MyJobs
                    setProfileField={setProfileField}
                    nobelprofileData={nobelprofileData}
                    profileField={profileField}
                    setSelectedJob={setSelectedJob}
                  />
                ) : profileField === "add-business-page" ? (
                  <BusinessPage
                    isProfilePage={true}
                    user={nobelprofileData}
                    setProfileField={setProfileField}
                  />
                ) : profileField === "view-applicant" ? (
                  <ViewApplicant
                    isProfilePage={true}
                    selectJob={selectJob}
                    setSelectedJob={setSelectedJob}
                    setProfileField={setProfileField}
                    nobelprofileData={nobelprofileData}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
      {addIntroModal && (
        <AddIntroVideo
          modal={addIntroModal}
          toggle={() => setAddIntroModal(!addIntroModal)}
          videoUrl={videoUrl}
          workerId={workerId}
        />
      )}
      {profilePhotoModal && (
        <ProfilePhotoModal
          modal={profilePhotoModal}
          toggle={() => setProfilePhotoModal(!profilePhotoModal)}
          title="Profile Photo"
          editProfileImage={nobelprofileData?.user?.profile_img}
          handleBannerPicture={handleBannerPicture}
          handleRemovePicture={handleRemovePicture}
          // setBannerModal={setBannerModal}
        />
      )}
      {publishModal && (
        <PublishYourProfile
          modal={publishModal}
          toggle={() => setPublishModal(!publishModal)}
          setPublishModal={setPublishModal}
          paymentModal={paymentModal}
          setPaymentModal={setPaymentModal}
        />
      )}
      {paymentModal && (
        <PublishPaymentModal
          modal={paymentModal}
          toggle={() => setPaymentModal(!paymentModal)}
          setPublishModal={setPublishModal}
          workerId={workerId}
        />
      )}
    </>
  );
};

export default WorkerPoolUserProfile;
