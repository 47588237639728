import React, { useEffect, useRef, useState } from "react";
import { Button, FormGroup, Input, Label, Progress } from "reactstrap";
import "../WorkerPoolDefaultForm.scss";
import edit from "../../../assets/img/edit.png";
import { call } from "../../../api/apiCall";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import {
  addeducationdetails,
  getNobelUserProfile,
  getWPUserProfileById,
  removeProfileSubData,
  updateWorkerUserDetails,
} from "../../../store/profile/ProfileAction";
import degre from "../../../assets/img/circle-degree.svg";
import moment from "moment/moment";
import DatePickerCustom from "../../../core/DatePicker/DatePickerReact";
import backArrow from "../../../assets/img/backArrow.svg";
import HeaderLogo from "../../Header/header";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import AppLoader from "../../../core/Loaders/appLoader/appLoader";
import Loader from "../../../core/Loaders/ComponentLoader";
import dustbin from "../../../assets/img/dustbin.svg";

const Education = (props) => {
  let {
    setCountinue,
    user,
    isProfilePage,
    setProfileField,
    isLoggedIn,
    token,
    workerId,
    setActivePage,
  } = props;
  const [editEducationData, setEditEducationData] = useState("");
  const [degreeErrorMessage, setDegreeErrorMessage] = useState("");
  const [fOSErrorMessage, setFOSErrorMessage] = useState("");
  const [degreeList, setDegreeList] = useState("");
  const [studyFieldList, setStudyFieldList] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  token = token || localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const [expData, setExpData] = useState(
    editEducationData
      ? {
          id: editEducationData.id,
          degree: editEducationData.degree,
          field_of_study: editEducationData.field_of_study,
        }
      : {
          degree: "",
          field_of_study: "",
        }
  );

  const dispatch = useDispatch();
  const { nobelprofileData } = useSelector((state) => state.profile);
  const { isLoading } = useSelector((state) => state.loader);
  const getStudyFields = () => {
    (async () => {
      try {
        const res = await call("get", "api/v1/study-field", null, null);
        setStudyFieldList(res.data);
      } catch (err) {
        console.log(err);
      }
    })();
  };
  useEffect(() => {
    (async () => {
      try {
        const res = await call("get", "api/v1/degree", null, null);
        setDegreeList(res.data);
      } catch (err) {
        console.log(err);
      }
    })();
    getStudyFields();
  }, []);
  useEffect(() => {
    let id = user?.id;
    if (workerId) {
      dispatch(getWPUserProfileById(workerId, token)).then((response) => {
        if (response?.success === false) return;
      });
    } else if (id) {
      dispatch(getWPUserProfileById(id, token)).then((response) => {
        if (response?.success === false) return;
      });
    }
  }, []);

  const checkValidation = () => {
    let formIsValid = true;
    if (!expData?.degree) {
      setDegreeErrorMessage("Mandatory Field");
      formIsValid = false;
    } else {
      setDegreeErrorMessage("");
    }
    if (!expData?.field_of_study) {
      setFOSErrorMessage("Mandatory Field");
      formIsValid = false;
    } else {
      setFOSErrorMessage("");
    }

    return formIsValid;
  };

  const handleSubmit = (e) => {
    setIsEdit(-1);
    e.preventDefault();
    if (checkValidation()) {
      let newData = expData;
      Object.keys(newData).forEach((key) => {
        if (!newData[key]) delete newData[key];
      });
      let expDataformat = {
        educations: [newData],
      };
      console.log(expDataformat);
      if (workerId)
        dispatch(
          updateWorkerUserDetails(expDataformat, token, nobelprofileData?.id)
        )
          .then((res) => {
            if (res?.success) {
              {
                isEdit
                  ? toast.success("Education updated Successfully", {
                      position: toast.POSITION.BOTTOM_LEFT,
                    })
                  : toast.success("Education added Successfully", {
                      position: toast.POSITION.BOTTOM_LEFT,
                    });
              }
              if (editEducationData) {
                setEditEducationData({});
              }
              setExpData({
                degree: "",
                field_of_study: "",
              });
            }
          })
          .catch((e) => {
            console.log(e);
            alert("Something went wrong.");
          });
      else
        dispatch(addeducationdetails(expDataformat, token))
          .then((res) => {
            console.log(res);
            if (res?.success) {
              {
                isEdit
                  ? toast.success("Education updated Successfully", {
                      position: toast.POSITION.BOTTOM_LEFT,
                    })
                  : toast.success("Education added Successfully", {
                      position: toast.POSITION.BOTTOM_LEFT,
                    });
              }
              if (editEducationData) {
                setEditEducationData({});
              }
              setExpData({
                degree: "",
                field_of_study: "",
              });
            }
          })
          .catch((e) => {
            console.log(e);
            alert("Something went wrong.");
          });
    }
  };

  const handleDelete = (id) => {
    dispatch(removeProfileSubData("education", id))
      .then((res) => {
        setIsEdit(false);
        delete expData?.id;
      })
      .catch((err) => {});
  };
  return (
    <>
      {isLoading && <AppLoader />}
      {!isProfilePage && !workerId && <HeaderLogo />}
      <div
        className={
          "education-info-page workerpool-default-form-page" +
          (isProfilePage ? " my-4" : "")
        }
      >
        <div className="container d-flex flex-column align-items-center">
          <div className="width-850">
            {isProfilePage && (
              <div className="workerpool-default-form-page">
                <div
                  className="back-btn"
                  onClick={() => setProfileField("profile")}
                >
                  <img src={backArrow} alt="Icon" />
                </div>
              </div>
            )}
            <div className="head progress-box">
              <h3 className="mb-2">Education</h3>
              {!isProfilePage && <Progress value={40} />}
            </div>
            <form className="form-box">
              <div className={"row" + (isProfilePage ? " mb-5" : "")}>
                <div className="row">
                  <div className="col-sm-5">
                    {/* <div className="row"> */}
                    <FormGroup>
                      <Label>Level of Education</Label>
                      <select
                        className="form-select"
                        onChange={(e) => {
                          setExpData({
                            ...expData,
                            degree: e.target.value,
                          });
                          setDegreeErrorMessage("");
                        }}
                        value={expData?.degree}
                      >
                        <option selected value="">
                          Select
                        </option>
                        {degreeList?.data?.map((item, idx) => (
                          <option value={`${item?.fullName} (${item?.name})`}>
                            {item?.fullName} ({item?.name})
                          </option>
                        ))}
                      </select>
                      {degreeErrorMessage && (
                        <label className="label-input100 text-danger">
                          {degreeErrorMessage}
                        </label>
                      )}
                    </FormGroup>
                    {/* </div> */}
                  </div>
                  <div className="col-sm-5">
                    {/* <div className="row"> */}
                    <FormGroup>
                      <Label>Field of Study</Label>
                      <select
                        className="form-select"
                        onChange={(e) => {
                          setExpData({
                            ...expData,
                            field_of_study: e.target.value,
                          });
                          setFOSErrorMessage("");
                        }}
                        value={expData?.field_of_study}
                      >
                        <option selected value="">
                          Select Field of Study
                        </option>
                        {studyFieldList?.data?.map((item, index) => {
                          return (
                            <option key={index} value={item.name}>
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                      {fOSErrorMessage && (
                        <label className="label-input100 text-danger">
                          {fOSErrorMessage}
                        </label>
                      )}
                    </FormGroup>
                    {/* </div> */}
                  </div>

                  <div
                    className="col-lg-2 col-12 text-center text-lg-end mb-3"
                    style={{ marginTop: "3.8%" }}
                  >
                    <Button
                      type="submit"
                      className="btn-blue mt-0"
                      onClick={(e) => handleSubmit(e)}
                    >
                      {isEdit > 0 ? "Save" : "Add"}
                    </Button>
                  </div>
                </div>
                <div className="col-sm-12 mt-5">
                  {nobelprofileData?.user?.educations.length > 0 && (
                    <div className="line mt-5"></div>
                  )}
                  {nobelprofileData?.user?.educations
                    // ?.toReversed()
                    .map((item, index) => (
                      <div className="edit-box" key={index}>
                        <div className="d-flex justify-content-between">
                          <h4>{item?.degree}</h4>
                          {/* Edit option */}
                          <div className="">
                            <span
                              className="edit-btn"
                              onClick={() => {
                                setExpData({
                                  id: item.id,
                                  degree: item.degree,
                                  field_of_study: item.field_of_study,
                                });
                                setIsEdit(item.id);
                                window.scrollTo(0, 0);
                              }}
                            >
                              {isEdit != item.id && (
                                <img src={edit} alt="Icon" />
                              )}
                            </span>
                            <span className="mx-3 edit-btn">
                              <img
                                src={dustbin}
                                onClick={() => handleDelete(item.id)}
                              />
                            </span>
                          </div>
                        </div>
                        <h5>{item?.field_of_study}</h5>
                      </div>
                    ))}
                </div>
              </div>
              {!isProfilePage && (
                <div className="text-end my-5 d-flex justify-content-between">
                  <Button
                    className="btn-outline no-bg mx-0 mx-md-4 mb-5"
                    onClick={() => {
                      isLoggedIn
                        ? navigate("/")
                        : workerId
                        ? setActivePage("")
                        : setCountinue(0);
                    }}
                  >
                    Back
                  </Button>
                  <div className="d-flex skip">
                    <h5
                      className="skip_btn mb-5 mx-4 me-3"
                      onClick={() => setCountinue(2)}
                    >
                      Skip
                    </h5>
                    <Button
                      className="btn-blue mb-5 mx-3 me-0"
                      onClick={() => setCountinue(2)}
                    >
                      Save & Continue
                    </Button>
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Education;
