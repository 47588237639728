import React, { useEffect, useState } from "react";
import "./PaymentModel.scss";
import {
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  NavLink,
} from "reactstrap";

const PaymentSuccessModel = ({ successModel, setSuccessModel }) => {
  return (
    <>
      <Modal
        isOpen={successModel}
        size="lg"
        centered
        className="profilePermission"
      >
        <ModalBody className="p-3">
          <div className="modal-header d-flex flex-column  p-5 text-white">
            <h3 className="text-center">Payment Successful</h3>
            <p className="text-center mt-3">
              Thank you! Your payment has been successfully processed. The
              selected profiles will now be in public mode for 30 days.
            </p>
          </div>

          <div className="d-flex flex-column align-items-center">
            <div className="d-flex justify-content-center align-items-center">
              <div
                className="noble-login-btns mb-3 px-5 py-2"
                onClick={() => setSuccessModel(!successModel)}
              >
                OK
              </div>
            </div>
          </div>
        </ModalBody>

        {/* <ModalBody>

        </ModalBody> */}
      </Modal>
    </>
  );
};

export default PaymentSuccessModel;
