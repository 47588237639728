import React, { useState } from "react";
import { Link } from "react-router-dom";
import rightArrow from "../../assets/img/Right.png";
import lockIcon from "../../assets/img/lock.png";
import { Modal, ModalBody } from "reactstrap";
import Header from "../../core/headers/Header";
import WorkCard from "../WorkerCard/WorkCard";
import Footer from "../../core/footers/footer";
import Login from "../login/login";
import AppLoader from "../../core/Loaders/appLoader/appLoader";
import { useSelector } from "react-redux";
import Loader from "../../core/Loaders/ComponentLoader";
import { profileApi } from "../../api/profileApi";
import AdBar from "../SidebarContent/AbBar/AdBar";
const WorkerList = (props) => {
  const {
    workerList,
    selectedCategory,
    setSelectedCategory,
    professionError,
    setSelectedProfession,
    setAge,
    setGender,
    setExperience,
    setNationality,
    setEmploymentType,
    createdBy,
    setCreatedBy,
    searchWorkers,
    countryList,
    experienceRegion,
    religionList,
    catergoryList,
    professionList,
    setRoe,
    user,
    searchAllWorkers,
    type,
    skip,
    age,
    selectedProfession,
    gender,
    nationality,
    employmentType,
    experience,
  } = props;

  const { isPageLoading } = useSelector((state) => state.loader);

  console.log(user);

  return (
    <>
      <Header
        isworkerpoolJobList={true}
        createdBy={createdBy}
        setCreatedBy={setCreatedBy}
        searchWorkers={searchWorkers}
        setAge={setAge}
        setNationality={setNationality}
        countryList={countryList}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
        experienceRegion={experienceRegion}
        religionList={religionList}
        catergoryList={catergoryList}
        professionList={professionList}
        setSelectedProfession={setSelectedProfession}
        professionError={professionError}
        setRoe={setRoe}
        setGender={setGender}
        setExperience={setExperience}
        setEmploymentType={setEmploymentType}
        user={user}
        type={type}
        age={age}
        selectedProfession={selectedProfession}
        gender={gender}
        nationality={nationality}
        employmentType={employmentType}
        experience={experience}
      />
      {isPageLoading && <AppLoader />}
      <section className="three-column-worker-section jobFilterList">
        <div className="container-big">
          <div className="row">
            <div className="col-xl-9 col-md-12 col-12">
              {workerList?.length > 0 ? (
                <div className="row">
                  {workerList?.map((item, index) => {
                    console.log(workerList, "workerList");
                    if (item?.is_public) {
                      return (
                        <div className="col-md-6 col-sm-12" key={index}>
                          <WorkCard workers={item} allWorkerList={true} />
                        </div>
                      );
                    } else {
                      return (
                        <div className="col-xl-6 col-md-6 col-12">
                          <div className="unlockProfile">
                            <WorkCard workers={item} allWorkerList={true} />
                            {user && user?.membership?.id !== 4 && (
                              <div className="unlockProfileLink">
                                <div className="iconBx rounded-circle d-flex align-items-center justify-content-center">
                                  <img src={lockIcon} alt="" />
                                </div>
                                <h4>Unlock Profile</h4>
                                <p>
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing elit.
                                </p>
                                <div className="btnGroup">
                                  <Link
                                    className="btn blueBtn"
                                    onClick={() =>
                                      window.open(
                                        `${process.env.REACT_APP_WEB_STAGE_URL}/membership`
                                      )
                                    }
                                  >
                                    Upgrade to scout
                                  </Link>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    }
                  })}

                  {isPageLoading && skip >= 1 && <Loader />}
                </div>
              ) : (
                <div>
                  {!workerList?.length && (
                    <>
                      <div className="d-flex justify-content-center align-items-center">
                        <p>No Worker Found</p>
                      </div>
                    </>
                  )}
                  <span
                    style={{ cursor: "pointer", color: "#19bcf4" }}
                    onClick={() => searchAllWorkers()}
                  >
                    {" "}
                    Find All Workers
                  </span>
                </div>
              )}
            </div>
            <div className="col-xl-3 col-md-12 col-12">
              {/* <div className="gradientCard">
                <h3>
                  Benefits of <span>NobelPage</span>
                </h3>
                <p>
                  Enjoy even more add-on packages, offering additional benefits
                  at discounted pricing. Your Professional Networking Platform,
                  where Skills Win over Bias!
                </p>
                <div className="actionGroup">
                  <div
                    className="btn btn-white"
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_WEB_STAGE_URL}/signup`
                      )
                    }
                  >
                    Join Now <img src={rightArrow} alt="right-arrow" />
                  </div>
                </div>
              </div> */}
              <AdBar />
              <div className="gradientCard">
                <h3>
                  Benefits of <span>NobelPage</span>
                </h3>
                <p>
                  Enjoy even more add-on packages, offering additional benefits
                  at discounted pricing. Your Professional Networking Platform,
                  where Skills Win over Bias!
                </p>
                <div className="actionGroup">
                  <div
                    className="btn btn-white"
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_WEB_STAGE_URL}/signup`
                      )
                    }
                  >
                    Join Now <img src={rightArrow} alt="right-arrow" />
                  </div>
                </div>
              </div>
              {/* <div className="gradientCard">
                <h3>
                  Benefits of <span>NobelPage</span>
                </h3>
                <p>
                  Enjoy even more add-on packages, offering additional benefits
                  at discounted pricing. Your Professional Networking Platform,
                  where Skills Win over Bias!
                </p>
                <div className="actionGroup">
                  <div
                    className="btn btn-white"
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_WEB_STAGE_URL}/signup`
                      )
                    }
                  >
                    Join Now <img src={rightArrow} alt="right-arrow" />
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};
export default WorkerList;
