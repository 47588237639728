import React from "react";
import "./WorkerDashboard.scss";
import { Link } from "react-router-dom";
import mobileAppIos from "../../assets/img/Mobile-app-store.png";
import mobileAppPlay from "../../assets/img/Mobile-play-store.png";
import rightArrow from "../../assets/img/rightArrowIcon.png";
import Header from "../../core/headers/Header";
import Footer from "../../core/footers/footer";
import FeatureJob from "../FeatureJobWorkPool/featureJob";
import WorkRequest from "../WorkRequestPool/WorkRequest";
import WorkCard from "../WorkerCard/WorkCard";
import LoginModal from "../login/component/LoginModal";
import PublishPaymentModal from "../WorkerPoolProfilePages/Componenets/PublishPaymentModal";
import PublishYourProfile from "../WorkerPoolProfilePages/Componenets/PublishYourProfile";
import AppLoader from "../../core/Loaders/appLoader/appLoader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { profileApi } from "../../api/profileApi";

const Dashboard = (props) => {
  const {
    workerList,
    jobList,
    staticData,
    loginPopUp,
    setLoginPopUp,
    selectedCategory,
    setSelectedCategory,
    professionError,
    setSelectedProfession,
    setAge,
    setGender,
    setExperience,
    setNationality,
    setEmploymentType,
    createdBy,
    setCreatedBy,
    searchWorkers,
    searcAllhWorkers,
    searchWorkersIndependent,
    searchWorkersAgency,
    countryList,
    experienceRegion,
    religionList,
    catergoryList,
    professionList,
    roe,
    setRoe,
    user,
    publishModal,
    setPublishModal,
    paymentModal,
    setPaymentModal,
    isPageLoading,
    employmentTypeList,
    setSearchJob,
    setJobExperience,
    setJobSalary,
    setJobAge,
    setJobProfessionError,
    setJobGender,
    setJobSelectedProfession,
    setSelectedJobCategory,
    jobProfessionError,
    setJobType,
    searchJobs,
    jobType,
    searchAllJobs,
    searchFullTimeJob,
    searchPartTimeJob,
    setJobCountry,
    locationList,
    getLocation,
    location,
    setLocation,
  } = props;

  return (
    <>
      {isPageLoading && <AppLoader />}
      <Header
        isWorkerPoolDashboard={true}
        createdBy={createdBy}
        setCreatedBy={setCreatedBy}
        searchWorkers={searchWorkers}
        setAge={setAge}
        setNationality={setNationality}
        countryList={countryList}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
        experienceRegion={experienceRegion}
        religionList={religionList}
        catergoryList={catergoryList}
        professionList={professionList}
        setSelectedProfession={setSelectedProfession}
        professionError={professionError}
        roe={roe}
        setRoe={setRoe}
        setGender={setGender}
        setExperience={setExperience}
        setEmploymentType={setEmploymentType}
        user={user}
        employmentTypeList={employmentTypeList}
        setSearchJob={setSearchJob}
        setJobExperience={setJobExperience}
        setJobSalary={setJobSalary}
        setJobAge={setJobAge}
        setJobProfessionError={setJobProfessionError}
        setJobGender={setJobGender}
        setJobSelectedProfession={setJobSelectedProfession}
        setSelectedJobCategory={setSelectedJobCategory}
        jobProfessionError={jobProfessionError}
        setJobType={setJobType}
        searchJobs={searchJobs}
        setJobCountry={setJobCountry}
        locationList={locationList}
        getLocation={getLocation}
        location={location}
        setLocation={setLocation}
      />
      <section className="four-quote-section">
        <div className="container-big">
          <div className="row">
            {staticData?.body?.map((item, index) => (
              <div className="col-lg-3 col-md-6 col-sm-12" key={index}>
                <div className="quote-box h-100">
                  <h3>{item?.title} </h3>
                  <p>{item?.body}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="three-column-worker-section">
        <div className="container-big">
          <div className="head">
            <div className="col">
              <div className="row justify-content-between">
                <div className="col-auto">
                  <h2>Workers</h2>
                  {workerList?.length > 0 && (
                    <p>Discover workers from NobelPage's WorkerPool.</p>
                  )}
                </div>
                <div className="col-auto mt-lg-0 mt-3">
                  <ul className="filterBtn d-flex flex-wrap gap-3">
                    <li>
                      <button
                        className={"btn " + (!createdBy ? "active" : "")}
                        onClick={(e) => {
                          searcAllhWorkers(e);
                        }}
                        style={{ padding: "7px 15px" }}
                      >
                        All
                      </button>
                    </li>
                    <li>
                      <button
                        className={
                          "btn " + (createdBy === "independent" ? "active" : "")
                        }
                        onClick={(e) => {
                          searchWorkersIndependent(e);
                        }}
                        style={{ padding: "7px 15px" }}
                      >
                        Independent
                      </button>
                    </li>
                    <li>
                      <button
                        className={
                          "btn " + (createdBy === "agency" ? "active" : "")
                        }
                        onClick={(e) => {
                          searchWorkersAgency(e);
                        }}
                        style={{ padding: "7px 15px" }}
                      >
                        Agency
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {!workerList?.length && (
              <>
                <div className="d-flex justify-content-center align-items-center">
                  <p>No Worker Found</p>
                </div>
              </>
            )}
            {workerList?.map((item, index) => {
              if (index < 6) {
                return (
                  <div className="col-xl-4 col-md-6 col-sm-12" key={index}>
                    <WorkCard workers={item} />
                  </div>
                );
              }
            })}
          </div>

          <div
            className="view-more-btn-outer"
            // onClick={(e) => {
            //   searcAllhWorkers(e);
            // }}
          >
            <Link to="/workers" state={{ createdBy }} className="view-more-btn">
              {workerList?.length >= 6 ? "View more" : "View All"}
            </Link>
          </div>
        </div>
      </section>
      <FeatureJob
        jobList={jobList}
        setJobType={setJobType}
        jobType={jobType}
        searchJobs={searchJobs}
        searchAllJobs={searchAllJobs}
        searchFullTimeJob={searchFullTimeJob}
        searchPartTimeJob={searchPartTimeJob}
      />
      <WorkRequest />
      <section className="join-now-cta-section">
        <div className="container-big">
          <div className="join-now-inner">
            <div className="left-box">
              <h3>Benefits of NobelPage</h3>
              <p>
                Discover limitless opportunities with NobelPage Membership!
                Access exclusive content, connect with industry leaders, and
                unlock your full potential. <br /> Enjoy even more add-on
                packages, offering additional benefits at discounted pricing.
                Join us today and elevate your career to new heights. Here,
                Skills Win over Bias!
              </p>
            </div>
            <div
              className="join-cta-btn"
              onClick={() =>
                window.open(`${process.env.REACT_APP_WEB_STAGE_URL}/signup`)
              }
            >
              <h5>Join Now</h5>
              <img src={rightArrow} alt="right-arrow" />
            </div>
          </div>
        </div>
      </section>
      <section className="app-pramotion">
        <div className="container-big bg-img">
          <div className="left-box col-lg-7 col-sm-12">
            <h2>
              Now available
              <span className="d-md-block d-inline">
                Download The WorkerPool App
              </span>
            </h2>
            <p>
              List yourself as a blue or pink collar worker or agency, and get
              discovered by a vast network of scouts and potential clients.
              Securely manage your profile on your phone and receive
              notifications instantly!
            </p>
            <div className="appBtn d-flex align-items-center gap-4 mt-md-5 mt-3">
              <div
                style={{ cursor: "pointer" }}
                onClick={() =>
                  window.open("https://play.google.com/store/apps?pli=1")
                }
              >
                <img src={mobileAppPlay} alt="App Store" />
              </div>
              <div
                style={{ cursor: "pointer" }}
                onClick={() =>
                  window.open("https://www.apple.com/in/app-store/")
                }
              >
                <img src={mobileAppIos} alt="App Store" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      {loginPopUp && (
        <LoginModal
          modal={loginPopUp}
          toggle={() => setLoginPopUp(!loginPopUp)}
          setLoginPopUp={setLoginPopUp}
        />
      )}
      {publishModal && (
        <PublishYourProfile
          modal={publishModal}
          toggle={() => setPublishModal(!publishModal)}
          setPublishModal={setPublishModal}
          paymentModal={paymentModal}
          setPaymentModal={setPaymentModal}
        />
      )}
      {paymentModal && (
        <PublishPaymentModal
          modal={paymentModal}
          toggle={() => setPaymentModal(!paymentModal)}
          setPublishModal={setPublishModal}
        />
      )}
    </>
  );
};

export default Dashboard;
